import * as React from 'react';
import styled from 'styled-components';
import { GradeLevelType, GradeType } from '@/summary/constants';
import { competencyShortNameMap } from '@/competency/constants';
import { CompetencyCode, CompetencyScores } from '@/competency/types';
import LogoSpinnerSVG from '@/ui/svg/LogoSpinner';
import { SpiderGraph } from '@/ui/SpiderGraph';

type CompetencyGraphProps = {
  scores: CompetencyScores,
  instructorScores?: GradeType,
  isLoading?: boolean
};

export const CompetencyGraph = (props: CompetencyGraphProps) => {
  const {
    scores,
    instructorScores,
    isLoading,
  } = props;

  // Convert a competency object to legacy format.
  const competencyValues: Array<{ grade: GradeLevelType | null, name: string, key: CompetencyCode }> = [];

  if (scores) {
    competencyShortNameMap.forEach((name: string, key: keyof CompetencyScores) => {
      competencyValues.push({
        name,
        grade: key in scores ? scores[key]?.score : null,
        key,
      });
    });
  }
  return (
    <Wrapper>
      {(isLoading || !scores)
        ? (
          <LoadingCompetencies>
            <LogoSpinnerSVG />
          </LoadingCompetencies>
        ) : (
          <SpiderGraph
            competencies={competencyValues}
            instructorScores={instructorScores}
          />
        )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 500px;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 24px;
  margin-bottom: 24px;
  border-radius: 8px;
  background: ${props => props.theme.mediumGrey.toString()};
  overflow: hidden;

  svg {
    overflow: visible !important;
  }
`;

const LoadingCompetencies = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
  margin: 0 auto;
`;

export default CompetencyGraph;
