import * as React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { HorizontalDivider, VerticalDivider } from '../../../ui/Divider';
import { Button } from '../../../ui/Button';
import { Row, Column, Container } from '../components/grid';
import { DateTime } from '../components/DateTime';
import { FullSessionType } from '../../../session/constants';

export interface StartPageProps {
  session: FullSessionType
  onStartSession: () => void;
}
const StartPage = (props: StartPageProps) => {
  const { session, onStartSession } = props;

  return (
    <Body>
      <Container>
        <Row>
          <Item style={{ flexGrow: 1 }}>
            <SessionTitle>
              {session.lessonPlanName}
            </SessionTitle>
          </Item>
          <Item><DateTime dt={session.scheduledFor} /></Item>
        </Row>
      </Container>
      <Container>

        <Row spacing={2} style={{ paddingBottom: 32 }}>
          <Column xs={12}>
            <ImageBannerBg>
              <ImageInfoBar>
                M-346 | ULTD_M-346
              </ImageInfoBar>
            </ImageBannerBg>
          </Column>
        </Row>
      </Container>

      <Container>
        <Row spacing={6}>
          <Column xs={12}>
            <ItemWrap>
              <Item>
                <PilotAvatar
                  url="/images/student-avatar.png"
                />
                <PilotNameText>{session.trainees.pilotFlying.name}
                </PilotNameText>
              </Item>
            </ItemWrap>
          </Column>
          <HorizontalDivider color="#CBCBCB" />
        </Row>
      </Container>

      <Container>
        <Row spacing={2}>
          <Column xs={12} sm={6} md={3}>
            <ItemWrap>
              <Item>Student:</Item>
              <Item>{session.trainees.pilotFlying.name}</Item>
            </ItemWrap>
          </Column>

          <VerticalDivider height={64} color="#CBCBCB" responseive />

          <Column xs={12} sm={6} md={3}>
            <ItemWrap>
              <Item>Date Scheduled:</Item>
              <Item>{moment(String(session.scheduledFor)).format(
                'MMMM Do, h:mm A',
              )}
              </Item>
            </ItemWrap>
          </Column>

          <VerticalDivider height={64} color="#CBCBCB" responseive />

          <Column xs={12} sm={6} md={3}>
            <ItemWrap>
              <Item>Aircraft Type:</Item>
              <Item>{session.equipment?.aircraftDetails?.aircraft?.[0].designator}</Item>
            </ItemWrap>
          </Column>

          <VerticalDivider height={64} color="#CBCBCB" responseive />

          <Column xs={12} sm={6} md={3}>
            <ItemWrap>
              <Item>Device:</Item>
              <Item>{session.equipment?.aircraftDetails?.aircraft?.[0].name}</Item>
            </ItemWrap>
          </Column>

          <Column xs={12}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button width="304px" height="64px" onClick={onStartSession}>
                START SESSION
              </Button>
            </div>

          </Column>
        </Row>
      </Container>

    </Body>
  );
};
export default StartPage;
const ImageBannerBg = styled.div`
  border-radius: 8px;
  width: 100%;
  min-height: 300px;
  height: 100%;
  background-image: url(/images/m346-fa.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;
const ImageInfoBar = styled.div`
position: relative;
bottom: -83%;
display: flex;
width: auto;
padding: 12px;
justify-content: center;
align-items: center;
background: rgba(17, 19, 34, 0.40);
backdrop-filter: blur(16px);
border-bottom-right-radius: 8px;
border-bottom-left-radius: 8px;
`;

const PilotAvatar = styled.div<{url: string}>`
width: 48px;
height: 48px;
border-radius: 100px;
border: 2px solid #FFF;

background-image: url(${props => props.url});
background-size: cover;
background-repeat: no-repeat;
`;
const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  gap: 16px;
`;

const ItemWrap = styled.div`
  padding-top: 15px;
`;

const PilotNameText = styled.span`
  color: #FFF;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 100;
  line-height: normal;
`;

const SessionTitle = styled.h2`
  flex: 1 0 0;
  color: #FFF;
  font-family: "Heebo", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 30.8px */
`;

const Body = styled.div`
  margin-top: 103px;
  padding-bottom: 2rem;
`;
