import React from 'react';
import styled from 'styled-components';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import moment from 'moment';
import {
  createSession,
  schedule,
  sessionOverview,
  traineeProfile,
} from '@/utils/routes';

import {
  SessionCardSquare,
  SessionCardSquareEmpty,
} from '@/ui/SessionCard';
import { Title, Trainee } from '@/ui/TraineeCard';
import {
  accent,
  accentBlue,
  lightestGrey,
  mediumGrey,
} from '@/theme/palette';
import { FullSessionType, TraineeType } from '@/session/constants';
import { useAppDispatch, useAppSelector } from '@/store';
import { logout } from '@/auth/actions';
import { useEffectOnce } from '@/hooks/useEffectOnce';
import { EquipmentType } from '@/dashboard/constants';
import { isNil, omitBy } from 'lodash';
import { detectOS } from '@/utils/helper';
import { Button } from '@/ui/Button';
import { media } from '@/theme/breakepoints';
import { Header } from '@/components/Header';
import Body from '@/ui/Body';
import PageHeader from '../../../ui/PageHeader';
import Loading from '../../../ui/Loading';
import QRCodeIcon from '../../../assets/icons/QRCodeIcon.png';

import ContentContainer from '../../../ui/ContentContainer';
import { GeneralStoreType } from '../../../store/constants';
import * as sessionListActions from '../../../sessionList/actions';
//  import { ToggleSwitchRow } from "../../../ui/form/ToggleSwitch";

const LandingPage = () => {
  const dispatch = useAppDispatch();
  const sessionList = useAppSelector(state => state.sessionList);
  const equipmentList: EquipmentType[] = useAppSelector(state => state.dashboard.equipment.toJS());
  const os = detectOS();

  const { isLoading, sessions } = sessionList;

  const onComponentMounted = () => {
    dispatch(sessionListActions.sessionListScreenLoaded());
  };

  useEffectOnce(() => {
    onComponentMounted();
  });

  const upcomingSessions: FullSessionType[] = [];
  const trainees: { [key: string]: TraineeType } = {};
  const currentDate = new Date().toISOString().substr(0, 10);
  localStorage.setItem('url', (window.location.href));

  sessions.forEach((session:any) => {
    if (!session.scheduledFor) {
      return;
    }

    const sessionDate = session.scheduledFor.substr(0, 10);

    if (currentDate <= sessionDate) {
      upcomingSessions.push(session);
    }

    if (
      session.trainees.pilotFlying.name !== ''
      && !trainees[session.trainees.pilotFlying.id]
    ) {
      trainees[session.trainees.pilotFlying.id] = session.trainees.pilotFlying;
    }

    if (
      session.trainees.pilotMonitoring.name !== ''
      && !trainees[session.trainees.pilotMonitoring.id]
    ) {
      trainees[session.trainees.pilotMonitoring.id] = session.trainees.pilotMonitoring;
    }
  });

  const generateScheduleURL = ({ equipmentId }:{equipmentId:number}) => {
    const urlParams = new URLSearchParams(omitBy({
      pilotFlying: undefined,
      pilotMonitoring: undefined,
      courseId: undefined,
      equipmentId: equipmentId.toString(),
      lessonPlanId: undefined,
      isCrossTrainingSession: undefined,
    }, isNil) as Record<string, string>);

    return `${createSession()}?${urlParams.toString()}`;
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Header />
      <Body>
        <ContentContainer>
          <SectionTitle>
            START A NEW TRAINING SESSION
          </SectionTitle>
          <SessionsHeader>
            Connect to the training device to start recording a session
          </SessionsHeader>
          <EquipmentWrapper>
            <EquipmentSelectionBlock>
              <QRCodeWrapper>
                <EquipmentTitle>Scan the QR Code </EquipmentTitle>
                <EquipmentDescription>Use your camera application to scan the provided QR code.</EquipmentDescription>
                <ImageWrapper>
                  <QRImageBox src={QRCodeIcon} alt="QR Image" />
                </ImageWrapper>
                {os === 'ANDROID' || os === 'IOS' ? (
                  <Button
                    as="label"
                    width="290px"
                    $margin="20px"
                  >

                    OPEN CAMERA
                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: 'none' }}
                      capture="environment"
                    />
                  </Button>
                ) : null}

              </QRCodeWrapper>
            </EquipmentSelectionBlock>
            <EquipmentSelectionDivider />
            <EquipmentSelectionBlock>
              <Wrapper>
                <EquipmentTitle>Select device from the list</EquipmentTitle>
                <Equipments>
                  {equipmentList.length > 0 && equipmentList.map(eq => {
                    const { name } = eq.aircraftDetails.aircraft[0];

                    return (
                      <Equipment key={eq.id} to={generateScheduleURL({ equipmentId: eq.id })}>
                        {name}
                      </Equipment>
                    );
                  })}
                </Equipments>

              </Wrapper>
            </EquipmentSelectionBlock>
          </EquipmentWrapper>
          <SectionTitle>RECENT SESSIONS</SectionTitle>
          <SessionsHeader>
            <>
              Upcoming Training Sessions
              <NavLink to={schedule()}>
                <ViewButton>View all</ViewButton>
              </NavLink>
            </>
          </SessionsHeader>
          <SessionsWrapper>
            {upcomingSessions.length > 0 ? (
              upcomingSessions.reverse().map(session => {
                const date = moment(String(session.scheduledFor));
                const courseName = session.courseName || '';
                const lessonName = session.lessonPlanShortName || '';
                const pilotFlyingName = session.trainees.pilotFlying.name;
                const pilotMonitoringName = session.trainees.pilotMonitoring.name;

                return (
                  <SessionCardSquare
                    key={session.id}
                    date={date}
                    buttonRoute={sessionOverview(session.id)}
                    courseName={courseName}
                    lessonName={lessonName}
                    pilotFlying={pilotFlyingName}
                    pilotMonitoring={pilotMonitoringName}
                  />
                );
              })
            ) : (
              <SessionCardSquareEmpty />
            )}
          </SessionsWrapper>
          <LowerWrapper>
            <Trainees>
              <Title>Your Trainees</Title>
              {Object.values(trainees).map((trainee: TraineeType) => (
                <NavLink key={trainee.id} to={traineeProfile(trainee.id)}>
                  <Trainee trainee={trainee.name} />
                </NavLink>
              ))}
            </Trainees>
          </LowerWrapper>
        </ContentContainer>
      </Body>
    </>
  );
};

export default LandingPage;

const SessionsHeader = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: ${lightestGrey.string()};
    line-height: 19px;
`;

const SectionTitle = styled.h3`
    font-size: 24px;
    font-weight: ${props => props.theme.fontWeightMedium};
    letter-spacing: 2px;
    text-align: center;
    margin-bottom: 40px;
`;

const ViewButton = styled.div`
  color: ${accent.string()};
`;

const SessionsWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const LowerWrapper = styled.div`
  margin-top: 30px;
  height: calc(100% - 435px);
  display: flex;
  justify-content: space-between;
`;

const Trainees = styled.div`
  height: 100%;
  width: calc(100% - 10px);
  background-color: ${mediumGrey.string()};
  border-radius: 5px;
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 142px;
    height: 142px;
    margin: 0 auto 20px;
`;

const QRImageBox = styled.img`
    width: 100%;
    height: 100%;
    justify-content: center;
`;

const EquipmentWrapper = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    margin-top: 18px;
    margin-bottom: 60px;
    overflow: auto;
    background-color: ${props => props.theme.mediumGrey.string()};
    border-radius: ${props => props.theme.borderRadius};
    min-height: 288px;

    ${media.sm} {
        flex-direction: column;
    }
`;

const EquipmentSelectionDivider = styled.span`
    position: absolute;
    right: 50%;
    top: 0;
    height: 100%;
    width: 1px;
    background: ${props => props.theme.lightGrey.string()} ;
    transform: translateX(-50%);

    &::after{
        position: absolute;
        content: "or";
        right: 50%;
        top: 50%;
        color: ${props => props.theme.lightGrey.string()} ;
        transform: translate(50%, -50%);
        background-color: ${props => props.theme.mediumGrey.string()};
        padding: 4px;
    }

    ${media.sm} {
        position: relative;
        width: 100%;
        height: 1px;
        right: unset;
        top: unset;
        transform: unset;
        margin-bottom: 8px;
    }
`;

const EquipmentSelectionBlock = styled.div`
    display: flex;
    justify-content: center;
    flex: 1 1 0;
    width: 100%;
    padding: 22px 0;
`;

const QRCodeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: start;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const EquipmentTitle = styled.div`
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 12px;
    padding: 0 22px;
    text-align: center;
    display: flex;
    justify-content: center;
`;

const EquipmentDescription = styled.div`
    font-size: 14px;
    margin-bottom: 24px;
    padding: 0 22px;
    color: ${props => props.theme.lightestGrey.string()};
    text-align: center;
`;

const Equipments = styled.div`
    padding: 0 22px;
`;

const Equipment = styled(Link)`
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    min-height: 36px;
    color: ${props => props.theme.lightestGrey.string()};
    border-bottom: 1px solid ${props => props.theme.lightestGrey.string()};
    margin-bottom: 8px;
    box-sizing: border-box;
    transition:  color 100ms ease-in, border-bottom-color  100ms ease-in;

    &::after {
        content: ">";
        position: absolute;
        right: 0;
        top: 50%;
        font-size: 24px;
        font-weight: 500;
        transform: translateY(-50%);
        color: inherit;
    }

    &:hover{
        color: ${props => props.theme.accent.string()};
        border-color: ${props => props.theme.accent.string()};

    }
`;
