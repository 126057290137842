import React from 'react';
import { PlotData } from 'plotly.js';
import { max, range } from 'lodash';
import { black, darkGrey, primary, tertiary, white } from '@/theme/palette';
import { gradeColors, GradeType, GradeValueType } from '@/summary/constants';
import { competencyShortNameMap } from '@/competency/constants';
import { CompetencyCode } from '@/competency/types';
import { RequiredNotNull } from '@/types/helper.types';
import { withGlobalPlotlyStyles } from '@/utils/plotly/utils';
import { useGradingScheme } from '@/summary/hooks';
import { getGradeValues } from '@/summary/utils';
import Plot from './Plotly';

const nextLayerColor = white.alpha(0.03).toString();
const shapeBgColor = darkGrey.toString();

const RANGE_RADIUS = 4;

type CompetencyValueType = { grade: GradeValueType | null, name: string, key: CompetencyCode }

type SpiderGraphProps = {
  competencies: Array<CompetencyValueType>,
  instructorScores?: GradeType,
};

const layout = withGlobalPlotlyStyles({
  margin: {
    l: 20,
    r: 20,
    t: 20,
    b: 20,
  },
  plot_bgcolor: 'transparent',
  polar: {
    bgcolor: 'transparent',
    angularaxis: {
      showline: false,
      ticklen: 0,
      tickfont: {
        color: white.toString(),
      },
      showgrid: false,
    },
    radialaxis: {
      visible: false,
    },
  },

});

const createBackgroundShape = (categories: string[], radius: number, color: string) => {
  const sides = categories.length;
  const theta = Array.from({ length: sides }, (_, i) => categories[i % sides]);
  return {
    type: 'scatterpolar',
    r: Array(sides).fill(radius),
    theta,
    fill: 'toself',
    fillcolor: color,
    line: { width: 0 },
    marker: {
      color: 'transparent',
    },
  };
};

const generateMainTraces = (competencies: CompetencyValueType[]):Partial<PlotData>[] => {
  const firstItem = competencies?.at(0);
  const data = [...competencies, ...(firstItem ? [firstItem] : [])];
  return [{
    mode: 'lines+markers',
    type: 'scatterpolar',
    r: data.map(d => d.grade),
    theta: data.map(d => competencyShortNameMap.get(d.key)!),
    fill: 'toself',
    fillcolor: primary.alpha(0.3).toString(),
    marker: {
      color: primary.toString(),
      size: 8,
    },
    line: {
      color: primary.toString(),
      width: 2,
    },
  }];
};

export const SpiderGraph = (props: SpiderGraphProps) => {
  const { competencies, instructorScores } = props;
  const gradingScheme = useGradingScheme();
  const gradeValues = getGradeValues(gradingScheme);
  const maxRange = max(gradeValues) ?? RANGE_RADIUS;

  const config = {
    displayModeBar: false,
    staticPlot: true,
    responsive: true,
  };

  // Process data
  const categories = [...new Set<string>(
    competencies.map(d => competencyShortNameMap.get(d.key as CompetencyCode) ?? ''),
  )];

  const competencyWithInstructorScores = competencies.map(d => {
    // @ts-ignore
    const instructorGrade = instructorScores?.[`${d.key}Grade`] as number;
    const grade = instructorGrade ?? d.grade;
    if (grade === null || grade === 0) return undefined;
    return {
      ...d,
      grade,
    };
  }).filter(d => d !== undefined) as RequiredNotNull<CompetencyValueType>[];

  // Prepare traces for each player
  const traces = generateMainTraces(competencyWithInstructorScores);

  // Background colors for grid shapes
  // Function to create background shapes as scatterpolar traces

  const shapeBgColors = range(0, maxRange)
    .map(val => (val === 0 ? shapeBgColor : nextLayerColor));

  // Create background shape traces
  const backgroundShapes = shapeBgColors
    .map((color, i) => createBackgroundShape(categories, maxRange - i, color));

  // Combine background shapes and player traces ...backgroundShapes,
  const allTraces = [...backgroundShapes, ...traces];

  return (
    <Plot
      config={config}
      data={allTraces}
      layout={layout}
      useResizeHandler
      style={{
        width: '100%',
        height: '460px',
      }}
    />
  );
};

export default SpiderGraph;
