import React from 'react';
import styled, { keyframes } from 'styled-components';

const LogoSpinnerSVG = () => (
  <svg
    viewBox="0 0 89 36"
    height="78"
    width="118"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
  >
    <g id="g8">
      <g id="g10">
        <path
          id="path12"
          d="M0.82251 35.7773H6.64991L26.8291 18.6046V31.6521H16.6981L11.7641 35.7773H26.8291H27.0697H30.9334V13.3354H27.1716L0.82251 35.7773Z"
          fill="#577AA5"
        />
      </g>
      <g id="g14">
        <g id="Clip path group">
          <mask
            id="mask0_5_92"
            maskUnits="userSpaceOnUse"
            x="-2"
            y="-4"
            width="94"
            height="44"
          >
            <g id="clipPath20">
              <path id="path18" d="M-1.08533 -3.27303H91.8554V39.2727H-1.08533V-3.27303Z" fill="white" />
            </g>
          </mask>
          <g mask="url(#mask0_5_92)">
            <g id="g16">
              <g id="g22">
                <path
                  id="path24"
                  d="M88.6043 29.1701C88.6043 29.9737 88.503 30.6967 88.3008 31.3385C88.0979 31.981 87.8307 32.5471 87.498 33.0375C87.1656 33.5283 86.776 33.9462 86.3297 34.2908C85.8833 34.635 85.4207 34.9173 84.9435 35.1362C84.465 35.3555 83.9797 35.517 83.4866 35.6217C82.9931 35.7261 82.5283 35.7783 82.092 35.7783H69.0082V31.7069H82.092C82.8714 31.7069 83.4762 31.4772 83.907 31.0181C84.3381 30.5584 84.5536 29.9427 84.5536 29.1701C84.5536 28.7942 84.4965 28.4496 84.3822 28.1367C84.2683 27.8231 84.1043 27.5517 83.8914 27.322C83.6784 27.0923 83.419 26.9151 83.1123 26.7901C82.8061 26.6648 82.4659 26.6022 82.092 26.6022H75.3183C74.6638 26.6022 73.9571 26.4841 73.1994 26.2495C72.4408 26.0149 71.7373 25.636 71.0884 25.1143C70.4391 24.5923 69.8989 23.9087 69.4678 23.063C69.037 22.2172 68.8212 21.1839 68.8212 19.9626C68.8212 18.741 69.037 17.7102 69.4678 16.8697C69.8989 16.0295 70.4391 15.3456 71.0884 14.8184C71.7373 14.2914 72.4408 13.91 73.1994 13.6754C73.9571 13.4408 74.6638 13.3233 75.3183 13.3233H88.2443L84.5293 17.3947H75.3183C74.5492 17.3947 73.9496 17.6293 73.5185 18.0992C73.0874 18.5687 72.8719 19.1896 72.8719 19.9626C72.8719 20.7453 73.0874 21.3643 73.5185 21.8182C73.9496 22.2724 74.5492 22.4995 75.3183 22.4995H82.092H82.1235C82.5594 22.5096 83.0217 22.5699 83.5096 22.6792C83.9979 22.7889 84.4835 22.9585 84.9665 23.1882C85.4496 23.418 85.9089 23.7077 86.3452 24.0571C86.7815 24.4073 87.1682 24.8272 87.5058 25.3182C87.8431 25.809 88.1109 26.3721 88.3086 27.0091C88.5053 27.646 88.6043 28.3664 88.6043 29.1701Z"
                  fill="#577AA5"
                />
              </g>
              <g id="g26">
                <path
                  id="path28"
                  d="M60.9904 17.0084V35.7783H65.0385V13.3367H64.2749L60.9904 17.0084Z"
                  fill="#577AA5"
                />
              </g>
              <g id="g30">
                <AnimatedBorder
                  id="path32"
                  d="M35.0285 14.1358L41.6843 21.9304L44.0048 19.7466L39.2389 14.1358H35.0285ZM41.6204 23.1116L33.2563 13.3171H39.6142L45.1286 19.8096L41.6204 23.1116Z"
                  fill="transparent"
                  stroke="#577AA5"
                  strokeWidth={1}

                />
              </g>
              <g id="g34">
                <AnimatedBorder
                  id="path36"
                  d="M52.7326 34.9528H56.958L50.79 27.7412L50.7939 27.7382L48.2687 24.7655L69.2958 0.916225L50.7368 18.289L35.2407 32.7689V34.9528H39.3037L46.1264 27.2433L52.7326 34.9528ZM58.7317 35.7708H52.3593L46.117 28.4861L39.6693 35.7708H34.4265V32.4129L42.8477 24.5439L42.8474 24.5436L43.1298 24.2747L68.8127 0.294327C68.9617 0.149128 69.1578 0.0685349 69.3656 0.0685349C69.8035 0.0685349 70.1599 0.426144 70.1599 0.866302C70.1599 1.03238 70.1122 1.189 70.023 1.3205L69.9918 1.35965L49.3477 24.7756L51.9694 27.8619L51.9678 27.8635L58.7317 35.7708Z"
                  fill="transparent"
                  stroke="#577AA5"
                  strokeWidth={1}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default LogoSpinnerSVG;

const StaticBorder = styled.path`
  stroke: #232323;
`;

const StrokeAnimation = keyframes`
  0% {
    stroke-dasharray: 0 200;
  }
  45% {
    stroke-dashoffset: 0;
    stroke-dasharray: 200 200;
  }
  90% {
    stroke-dashoffset: -200;
    stroke-dasharray: 200 200;
  }
  100% {
    stroke-dashoffset: -200;
    stroke-dasharray: 200 200;
  }
`;

const AnimatedBorder = styled.path`
  -webkit-animation: ${StrokeAnimation} 3s ease-in;
          animation: ${StrokeAnimation} 3s ease-in;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
`;
