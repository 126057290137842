import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { isNil } from 'lodash';
import { Header } from '@/components/Header';

import { BreadcrumbListType, Breadcrumbs } from '@/components/Breadcrumbs';
import Body from '@/ui/Body';
import PageHeader from '../../../ui/PageHeader';
import {
  Button,
  BUTTON_BACKGROUND_GRADIENT,
  Wrapper as ButtonWrapper,
  BUTTON_BORDER_DISABLED,
} from '../../../ui/Button';
import {
  accent,
  accentBlue, background,
  lightestGrey,
  lightGrey,
  mediumGrey,
} from '../../../theme/palette';
import Loading from '../../../ui/Loading';
import {
  editSessionDetails,
  gradingSheet, home,
  liveSessionRun, schedule as scheduleRoute, sessionOverview,
  traineeProfile,
  viewSession,
} from '../../../utils/routes';
import * as utils from '../../../utils';
import { Trainee } from '../../../ui/TraineeCard';
import ContentContainer from '../../../ui/ContentContainer';
import ClassifierProgress from './ClassifierProgress';
import { hasSessionsExceededDuration } from '../../../session/utils';
import { StartDemoPage } from '../../../demo/pace-leonardo/start';
import { getTrainingCenter } from '../../../dashboard/selectors';
import { CalculatingDemoPage } from '../../../demo/pace-leonardo/calculating';
import * as actions from '../../actions';
import * as sessionActions from '../../../session/actions';
import { useAppDispatch, useAppSelector } from '../../../store';
import { ETrainingCenter } from '../../../types/enums.types';
import { FullSessionType } from '../../../session/constants';

const getSessionInfo = (session?:FullSessionType) => {
  if (!session) return null;
  if (utils.isRunning(session)) {
    return {
      btnText: 'CHECK TRAINING',
      disabled: true,
      btnRoute: liveSessionRun(session.id),
      sessionEnded: false,
      sessionStarted: true,
      isEditPossible: false,
      sessionTimeExceeded: false,
    };
  }
  if (utils.isEnded(session)) {
    return {
      btnText: 'REVIEW',
      disabled: true,
      sessionEnded: true,
      sessionStarted: false,
      isEditPossible: false,
      btnRoute: gradingSheet(session.id),
      sessionTimeExceeded: hasSessionsExceededDuration(session),
    };
  }
  if (session.plannedRuns && session.plannedRuns[0]) {
    return {
      btnText: 'START PART 1',
      disabled: false,
      btnRoute: liveSessionRun(session.id),
      sessionEnded: false,
      sessionStarted: false,
      sessionTimeExceeded: false,
      isEditPossible: true,
    };
  }

  return null;
};

const getSession = (sessions:FullSessionType[], sessionId:number) => {
  const sessionIndex = sessions.findIndex(({ id }) => id === sessionId);
  return sessions?.[sessionIndex];
};

const SessionOverview = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const sessionSelector = useAppSelector(state => state.session.toJS());
  const sessionListSelector = useAppSelector(state => state.sessionList.toJS());
  const dashboardSelector = useAppSelector(state => state.dashboard.toJS());
  const { currentSession, runs, currentEquipmentActiveRunIds, canStartSession } = sessionSelector;
  const { sessions, isLoading, classifierStatus } = sessionListSelector;
  const { equipment } = dashboardSelector;
  const params = useParams<{sessionId:string}>();
  const sessionId = parseInt(params.sessionId!, 10);

  const onComponentMounted = useCallback((
    id: number,
  ) => dispatch(actions.sessionOverviewScreenLoaded(id)), [sessionId]);
  const onEndActiveSessions = () => dispatch(sessionActions.endActiveSessions());
  const fetchCurrentEquipmentActiveRuns = (
    redirectTo: string,
  ) => dispatch(sessionActions.fetchCurrentEquipmentActiveRuns(redirectTo));

  const { trainingCenter } = useSelector(getTrainingCenter);

  useEffect(() => {
    onComponentMounted(sessionId!);
  }, [onComponentMounted, sessionId]);

  const onGetReviewStatus = useCallback((status:any) => {
    if (status) {
      return status.progress !== 1.0;
    }
    if (status?.progress === 1) {
      return false;
    }

    return false;
  }, [classifierStatus]);

  const reviewDisabled = onGetReviewStatus(classifierStatus);

  useEffect(() => {
    if (canStartSession) {
      const sessionInfo = getSessionInfo(getSession(sessions, sessionId));
      !isNil(sessionInfo) && navigate(sessionInfo.btnRoute);
    }
  }, [canStartSession]);

  const endRunningEquipmentSessions = useCallback(() => {
    console.log(currentEquipmentActiveRunIds);

    if (
    // eslint-disable-next-line no-alert
      window.confirm(
        `There are active sessions running on selected equipment - starting this session will end those active sessions.
Are you sure you want to continue?`,
      )
    ) {
      onEndActiveSessions();
    }
  }, [currentEquipmentActiveRunIds]);

  useEffect(() => {
    if (currentEquipmentActiveRunIds.length) {
      endRunningEquipmentSessions();
    }
  }, [currentEquipmentActiveRunIds]);

  if (
    Object.values(currentSession).length < 1
    || runs.length < 1
    || equipment.length < 1
    || isLoading
  ) {
    return <Loading />;
  }

  const date = moment(String(currentSession.scheduledFor)).format(
    'MMMM Do, h:mm A',
  );
  const course = currentSession.courseName || '';
  const lessonPlan = currentSession.lessonPlanName || currentSession.name || '';
  const aircraft = equipment[0]?.aircraftDetails.aircraft[0].designator;
  const device = equipment[0]?.aircraftDetails.aircraft[0].name;

  const session = getSession(sessions, sessionId);

  const editBtnRoute = editSessionDetails(session.id);

  const sessionInfo = getSessionInfo(session);

  if (isNil(sessionInfo)) return null;

  const handleButtonClick = () => {
    const buttonRoute = sessionInfo.btnRoute;
    if (utils.isEnded(session) || utils.isRunning(session)) {
      navigate(buttonRoute);
    } else {
      fetchCurrentEquipmentActiveRuns(buttonRoute);
    }
  };

  const handleEditButtonClick = () => {
    navigate(editBtnRoute);
  };
  const breadcrumbs: BreadcrumbListType = [
    {
      link: home(),
      title: 'Home',
    },
    {
      link: scheduleRoute(),
      title: 'Schedule',
    },

    {
      title: currentSession?.shortName,
    },
  ];

  return (
    <>
      <Header />
      {trainingCenter === ETrainingCenter.LEONARDO ? (
      // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {sessionInfo.sessionEnded ? (
            <CalculatingDemoPage
              session={session}
              onReview={handleButtonClick}
            />
          ) : (
            <StartDemoPage
              session={session}
              onStartSession={handleButtonClick}
            />
          )}
        </>
      ) : (
        <ContentContainer>
          <Body>
            <Breadcrumbs crumbs={breadcrumbs} />
            <ButtonWrapper>
              <MarginTop>
                <Button
                  width="290px"
                  $margin="auto"
                  variation={reviewDisabled
                    ? BUTTON_BORDER_DISABLED
                    : BUTTON_BACKGROUND_GRADIENT}
                  $backgroundColor={background.toString()}
                  disabled={sessionInfo.sessionEnded && reviewDisabled}
                  onClick={handleButtonClick}
                >
                  {sessionInfo.btnText}
                </Button>
                {!sessionInfo.sessionEnded
                   && currentEquipmentActiveRunIds
                   && !!currentEquipmentActiveRunIds.length && (
                     <EndActiveSessionsButton
                       onClick={endRunningEquipmentSessions}
                     />
                )}
                {sessionInfo.isEditPossible ? (
                  <EditTop>
                    <Button
                      width="160px"
                      $margin="auto"
                      variation={BUTTON_BACKGROUND_GRADIENT}
                      $backgroundColor={background.string()}
                      onClick={handleEditButtonClick}
                    >
                      EDIT
                    </Button>
                  </EditTop>
                ) : null}
              </MarginTop>
            </ButtonWrapper>

            {classifierStatus && (
            <ClassifierProgress progress={classifierStatus.progress} />
            )}
            {sessionInfo.sessionTimeExceeded
               && (
                 <SessionTimeExceeded>
                   The maximum duration for a session has been exceeded. Flight
                   analytics and assessments will be limited to the first 6 hours
                   of this session
                 </SessionTimeExceeded>
               )}
            <Wrapper>
              <Section>
                <Tile>
                  <Title>
                    Lesson Plan
                    <NavLink to={viewSession(session.id)}>
                      <EditButton disabled={sessionInfo.disabled}>Edit</EditButton>
                    </NavLink>
                  </Title>
                  <Content>{lessonPlan}</Content>
                </Tile>
                <Tile>
                  <Title>Pilots</Title>
                  <PilotContent>
                    <NavLink
                      to={traineeProfile(session.trainees.pilotFlying.id)}
                    >
                      <Trainee
                        trainee={session.trainees.pilotFlying.name}
                        hideLine
                      />
                    </NavLink>
                    {session.trainees.pilotMonitoring && (
                    <>
                      <Line />
                      <NavLink
                        to={traineeProfile(
                          session.trainees.pilotMonitoring.id,
                        )}
                      >
                        <Trainee
                          trainee={session.trainees.pilotMonitoring.name}
                          hideLine
                        />
                      </NavLink>
                    </>
                    )}
                  </PilotContent>
                </Tile>
              </Section>
              <Section>
                <Tile>
                  <Title>Date Scheduled</Title>
                  <Content>{date}</Content>
                </Tile>
                <Tile>
                  <Title>Aircraft Type</Title>
                  <Content>{aircraft}</Content>
                </Tile>
                <Tile>
                  <Title>Device</Title>
                  <Content>{device}</Content>
                </Tile>
              </Section>
            </Wrapper>
          </Body>
        </ContentContainer>
      )}
    </>
  );
};

export default SessionOverview;

const Wrapper = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: space-evenly;
  gap: 24px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const Tile = styled.div`
  background-color: ${mediumGrey.string()};
  border-radius: 5px;
  margin-bottom: 20px;
`;

const Title = styled.div`
  font-size: 16px;
  color: ${lightestGrey.string()};
  line-height: 19px;
  padding: 15px 20px;
  border-bottom: 1px solid ${lightGrey.string()};
  display: flex;
  justify-content: space-between;
  font-weight: 400;
`;

const EditButton = styled.div<{ disabled: boolean }>`
  font-size: 16px;
  color: ${accent.string()};
  font-weight: 400;
  visibility: ${props => (props.disabled ? 'hidden' : 'visible')};
`;

const Content = styled.div`
  padding: 20px;
  font-weight: 400;
`;

const PilotContent = styled(Content)`
  padding: 20px 0;
`;

const Line = styled.div`
  width: 100%;
  margin: 20px 0;
  border-bottom: 1px solid ${lightGrey.string()};
`;

const MarginTop = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

const EndActiveSessionsButton = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: transparent;
  cursor: pointer;
`;

const SessionTimeExceeded = styled.p`
  text-align: center;
`;

const EditTop = styled.div`
  position: absolute;
  right: 0;
`;
