import * as React from 'react';
import { PropsWithChildren, ReactElement, useRef, useState } from 'react';
import styled from 'styled-components';
import { HorizontalDivider } from '@/ui/Divider';
import ArrowIcon from '@/assets/icons/ArrowIcon.svg';

type AccordionProps = {
  title: string | ReactElement
  iconPath?: string
};

export const Accordion = ({
  children, title, iconPath,
}: PropsWithChildren<AccordionProps>) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleHandler = () => {
    setIsOpen(prevState => !prevState);
  };
  return (
    <AccordionStyles>
      <AccordionTop onClick={toggleHandler} $active={isOpen}>
        <AccordionInfo>
          {iconPath ? (
            <Icon>
              <img src={iconPath} alt="bar icon" />
            </Icon>
          ) : null}
          <AccordionTitle>
            {title}
          </AccordionTitle>
        </AccordionInfo>

        <AccordionIcon $active={isOpen}>
          <img src={ArrowIcon} alt="arrow icon" />
        </AccordionIcon>
      </AccordionTop>
      <HorizontalDivider />
      <AccordionWrapper ref={wrapperRef} $active={isOpen} $height={wrapperRef?.current?.scrollHeight ?? 0}>
        {children}
      </AccordionWrapper>

    </AccordionStyles>
  );
};

const AccordionStyles = styled.div`
    width: 100%;
    background: ${props => props.theme.mediumGrey.toString()};
    border-radius: 8px;
    overflow: hidden;
`;

const AccordionInfo = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-align: left;
    overflow: hidden;
    gap: 8px;
    line-height: 1;
`;

const AccordionTitle = styled.div`
    width: 100%;
    color: ${props => props.theme.white.toString()};
    padding-right: 40px;
    font-size: 16px;
`;
const AccordionIcon = styled.div<{ $active: boolean }>`
    position: absolute;
    width: 16px;
    height: 16px;
    right: 20px;
    top: 50%;
    transition: transform 150ms ease;
    transform: ${props => (!props.$active ? 'translate(0, -50%) rotate(180deg)' : ' translate(0, -50%) rotate(0deg)')};

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`;

const Icon = styled.div`
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`;

const AccordionWrapper = styled.div<{ $active: boolean, $height:number }>`
    width: 100%;
    transition: height 200ms ease-in-out;
    height: ${props => (!props.$active ? 0 : `${props.$height}px`)};
overflow: hidden;

`;
const AccordionTop = styled.button<{ $active: boolean }>`
    display: flex;
    position: relative;
    cursor: pointer;
    width: 100%;
    background: inherit;
    border: none;
    padding:  16px 20px 16px 16px;
    border-bottom: ${props => (props.$active ? `1px solid ${props.theme.mediumLightGrey.toString()}` : 'none')};
`;

export default Accordion;
