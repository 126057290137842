import styled from 'styled-components';

export const InputWrapper = styled.div`
  display: flex;
  width: 320px;
  border-bottom: 1px solid #B1B3B7;
  border-radius: 0;
  margin: 8px 0;
`;

export const Input = styled.input`
  border: none;
  background: transparent;
  color: white;
  box-sizing: border-box;
  font-size: 16px;
  padding: 15px;
  width: 300px;
  max-width: 500px;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: white;
  }

  &:focus {
    outline: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: white;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }
`;
