import React from 'react';
import { isEmpty, keyBy } from 'lodash';
import {
  CasMarkerType,
  NormEvidenceObject,
  NormType,
} from '../../../utils/visualization/constants';
import { combineNormMarkersLabels } from '../Norms/utils';
import { SVGRunwayCenter } from '../Runway/constants';
import { NormMarkerSvg } from './types';
import { gradeLevel5, gradeLevel9 } from '../../../theme/palette';

const LandingNormCodeToNameMap = new Map([
  ['TOUCHDOWN_CAS', 'TOUCHDOWN'],
  ['SPD_BRK_DEPLOYMENT_TIME', 'SPEEDBRAKE DEPLOYED'],
  ['BRAKES_APPLIED_CAS', 'BRAKE ACTIVE'],
  ['REV_DEPLOYMENT_TIME', 'REVERSE THRUST DEPLOYED'],
  ['REV_IDLE_CAS', 'REVERSE THRUST IDLE'],
  ['TURNOFF', 'TURNOFF'],
  ['SPD_BRK_RETRACTED_CAS', 'SPEEDBRAKE STOWED'],
]);

export const getColor = (pass?: boolean | null) => {
  switch (pass) {
    case true:
      return gradeLevel9.toString();

    case false:
      return gradeLevel5.toString();

    default:
      return '#979797';
  }
};

export const RunwayContaminantNameMap = new Map([
  [0, 'DRY'],
  [1, 'DAMP'],
  [2, 'WET'],
  [3, 'FLOODED'],
  [4, 'NO WATER DEPTH'],
  [5, 'FLOODED DEPTH'],
  [6, 'MELTING SLUSH'],
  [7, 'FREEZING SLUSH'],
  [8, 'WET SNOW'],
  [9, 'DRY SNOW'],
  [10, 'WET ICE'],
  [11, 'COLD ICE'],
  [12, 'ICY'],
  [13, 'PATCHY WET'],
  [14, 'PATCHY ICE'],
]);

export const RunwayContaminantXMap = new Map([
  [0, 148],
  [1, 143],
  [2, 147],
  [3, 133],
  [4, 109],
  [5, 112],
  [6, 114],
  [7, 111],
  [8, 128],
  [9, 129],
  [10, 135],
  [11, 133],
  [12, 149],
  [13, 123],
  [14, 126],
]);

const getWindAnnotation = (speed: number, direction: number) => {
  if (speed < 2) {
    return 'CALM WIND';
  }

  return `${Math.round(direction)} @ ${Math.round(speed)} KTS`;
};

export const getWindBox = (
  windSpeed: number,
  windDirection: number,
  relWindDirection: number,
  data: { rwyContaminantType: number; massAc: number; flapLeverPos: number },
  autobrake?: 'True' | 'False' | '',
) => (
  <>
    <rect stroke="#979797" x="84.5" y="20.5" width="149" height="233" rx="10" />
    <text
      fontFamily="Heebo"
      fontSize="12"
      fontWeight="400"
      fill="#979797"
    >
      <tspan x="144.347656" y="50">
        WIND
      </tspan>
    </text>
    <text
      fontFamily="Heebo"
      fontSize="12"
      fontWeight="400"
      fill="#979797"
    >
      <tspan x="121.054688" y="69">
        {getWindAnnotation(windSpeed, windDirection)}
      </tspan>
    </text>
    {windSpeed >= 2 && (
      <g
        transform={`translate(132.000000, 79.000000) rotate(${relWindDirection} 24.5 24.5)`}
      >
        <circle cx="24.5" cy="24.5" r="24.5" />
        <path
          d="M25,6 L25,40 L29,40 L24.5,49 L20,40 L24,40 L24,6 L25,6 Z"
          fill="#979797"
          fillRule="nonzero"
        />
        <polyline
          stroke="#979797"
          strokeLinecap="square"
          points="19 1 24.5 6.53882769 29 1"
        />
        <polyline
          stroke="#979797"
          strokeLinecap="square"
          points="19 6 24.5 11.5388277 29 6"
        />
      </g>
    )}
    <text
      fontFamily="Heebo"
      fontSize="12"
      fontWeight="400"
      fill="#ffbf00"
    >
      <tspan x={RunwayContaminantXMap.get(data.rwyContaminantType)} y="148">
        {RunwayContaminantNameMap.get(data.rwyContaminantType)}
      </tspan>
    </text>
    <line
      x1="86"
      y1="168"
      x2="233"
      y2="168"
      id="Line-7"
      stroke="#979797"
      strokeLinecap="square"
    />
    <text
      fontFamily="Heebo"
      fontSize="12"
      fontWeight="400"
      fill="#979797"
    >
      <tspan x="104.833008" y="193">
        WEIGHT: {Math.round(data.massAc)} Kg
      </tspan>
    </text>
    <text
      fontFamily="Heebo"
      fontSize="12"
      fontWeight="400"
      fill="#979797"
    >
      <tspan x="131.103516" y="215">
        FLAPS: {data.flapLeverPos}
      </tspan>
    </text>
    {!isEmpty(autobrake) && (
    <text
      fontFamily="Heebo"
      fontSize="12"
      fontWeight="400"
      fill={autobrake === 'True' ? '#00FF00' : '#CCAA00'}
    >
      <tspan x="98.103516" y="233">
        AUTO BRAKE {autobrake === 'True' ? 'ARMED' : 'DISARMED' }
      </tspan>
    </text>
    )}

  </>
);

export const getLandingLatDevLine = (
  latDev: [number, number][],
  normalizeFactor: number,
  normalizeFactorX: number,
  // eslint-disable-next-line default-param-last
  offset: number = 0,
  y: number,
  markers: object,
) => {
  const shapes = [];
  const y0 = offset + y;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const points = latDev.sort(([_x1, y1], [_x2, y2]) => y1 - y2);

  // @ts-ignore
  const touchdownMarker = markers.find((
    marker: { label: string; },
  ) => marker.label.toLowerCase().includes('touchdown'));

  for (let i = 0; i < points.length - 1; i++) {
    const [x1, x2, y1, y2] = [
      SVGRunwayCenter + points[i][0] * normalizeFactorX,
      SVGRunwayCenter + points[i + 1][0] * normalizeFactorX,
      y0 - points[i][1] * normalizeFactor,
      y0 - points[i + 1][1] * normalizeFactor,
    ];

    shapes.push(
      <line
        x1={x1}
        x2={x2}
        y1={y1}
        y2={y2}
        stroke="#0097a7"
        strokeLinecap="square"
        strokeWidth="2"
        key={`${i} ${y1}`}
      />,
    );

    if (i === 0) {
      shapes.push(
        <circle
          key="lat_dev_touchdown"
          cx={x1}
          cy={y1}
          r="5"
          fill={getColor(touchdownMarker?.pass)}
        />,
      );
    }
  }
  return shapes;
};

export const getLandingNormsMarkers = (
  payload: NormType[],
  casMarkers: CasMarkerType[],
  offset: number,
  normalizeFactor: number,
): NormMarkerSvg[] => {
  const normMarkers: NormMarkerSvg[] = [];

  //  TURNOFF
  normMarkers.push(
    ...casMarkers
      .filter((marker: CasMarkerType) => marker.cas === 35)
      .map((marker: CasMarkerType) => ({
        position: marker.position,
        pass: true,
        label: `${LandingNormCodeToNameMap.get('TURNOFF')}`,
      })),
  );

  const normCodes = keyBy(payload, 'normCode');

  //  TOUCHDOWN
  const touchdownCas = normCodes?.TOUCHDOWN_CAS;
  const touchdownDist = normCodes?.TOUCHDOWN_DIST;

  if (
    touchdownCas?.evidence
    && (touchdownCas.evidence as NormEvidenceObject).measure
    && touchdownDist?.evidence
    && (touchdownDist.evidence as NormEvidenceObject).measure
  ) {
    const isPassed = [
      'TOUCHDOWN_CAS',
      'TOUCHDOWN_DIST',
      'TOUCHDOWN_PITCH',
      'TOUCHDOWN_ROD',
    ].every(key => normCodes?.[key]?.pass);

    const speed = `${parseInt(
      `${(touchdownCas.evidence as NormEvidenceObject).measure}`,
      10,
    )} KTS`;

    normMarkers.push({
      position: (touchdownDist.evidence as NormEvidenceObject).measure,
      pass: isPassed,
      label: `${LandingNormCodeToNameMap.get(touchdownCas.normCode)} ${speed}`,
      latDev: true,
    });
  }

  // BRAKE ACTIVE
  const brakeActive = normCodes?.BRAKES_APPLIED_CAS;

  if (
    brakeActive?.evidence
    && (brakeActive.evidence as NormEvidenceObject).distToRwyThld
  ) {
    normMarkers.push({
      position: (brakeActive.evidence as NormEvidenceObject).distToRwyThld,
      pass: brakeActive.pass,
      label: `${LandingNormCodeToNameMap.get(brakeActive.normCode)}`,
    });
  }

  // REVERSE THRUST IDLE
  const reverseThrustIdle = normCodes?.REV_IDLE_CAS;

  if (
    reverseThrustIdle?.evidence
    && (reverseThrustIdle.evidence as NormEvidenceObject).distToRwyThld
  ) {
    normMarkers.push({
      position: (reverseThrustIdle.evidence as NormEvidenceObject)
        .distToRwyThld as number,
      pass: reverseThrustIdle.pass,
      label: `${LandingNormCodeToNameMap.get(reverseThrustIdle.normCode)}`,
    });
  }

  // REVERSE THRUST DEPLOYED
  const reverseThrustDeployed = normCodes?.REV_DEPLOYMENT_TIME;

  if (
    reverseThrustDeployed?.evidence
    && (reverseThrustDeployed.evidence as NormEvidenceObject).distToRwyThld
  ) {
    normMarkers.push({
      position: (reverseThrustDeployed.evidence as NormEvidenceObject)
        .distToRwyThld,
      pass: reverseThrustDeployed.pass,
      label: `${LandingNormCodeToNameMap.get(reverseThrustDeployed.normCode)}`,
    });
  }

  // SPEEDBRAKE DEPLOY
  const speedBrakeDeploymentTime = normCodes?.SPD_BRK_DEPLOYMENT_TIME;

  if (
    speedBrakeDeploymentTime?.evidence
    && (speedBrakeDeploymentTime.evidence as NormEvidenceObject).distToRwyThld
  ) {
    normMarkers.push({
      position: (speedBrakeDeploymentTime.evidence as NormEvidenceObject)
        .distToRwyThld,
      pass: speedBrakeDeploymentTime.pass,
      label: `${LandingNormCodeToNameMap.get(
        speedBrakeDeploymentTime.normCode,
      )}`,
    });
  }

  //  SPEEDBRAKE STOWED
  const speedBrakeStowed = normCodes?.SPD_BRK_RETRACTED_CAS;

  if (
    speedBrakeStowed?.evidence
    && (speedBrakeStowed.evidence as NormEvidenceObject).distToRwyThld
  ) {
    normMarkers.push({
      position: (speedBrakeStowed.evidence as NormEvidenceObject).distToRwyThld,
      pass: speedBrakeStowed.pass,
      label: `${LandingNormCodeToNameMap.get(speedBrakeStowed.normCode)}`,
    });
  }

  // todo: 50 feet / threshold crossing height
  return combineNormMarkersLabels(normMarkers, normalizeFactor);
};
