import * as React from 'react';
import styled from 'styled-components';
import { RunIdType, TraineeType } from '@/session/constants';
import { NavLink } from 'react-router-dom';
import { traineeProfile } from '@/utils/routes';
import { media } from '@/theme/breakepoints';

type RunSelectorType = (id: number) => void;

type Props = {
    runIds: RunIdType[],
    selectedRunId: RunIdType | null,
    pilotFlying: TraineeType,
    pilotMonitoring?: TraineeType,
    onSelectRun: RunSelectorType,
};

const TraineeTabs = (props: Props) => {
  const {
    onSelectRun,
    runIds,
    selectedRunId,
    pilotMonitoring,
    pilotFlying,
  } = props;

  return (
    <Wrapper>
      {runIds.map((id, i) => (
        <RunSelector
          key={id}
          id={id}
          pilot={i === 0 ? pilotFlying : pilotMonitoring}
          onSelectRun={onSelectRun}
          selected={id === selectedRunId}
        />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 12px;
`;

type RunSelectorProps = {
    id: number,
    onSelectRun: RunSelectorType,
    selected?: boolean,
    pilot?: TraineeType
};

const RunSelector = (props: RunSelectorProps) => {
  const onClick = () => props.onSelectRun(props.id);

  if (!props?.pilot) return null;

  return (
    <SelectedButton
      type="button"
      onClick={onClick}
      selected={props.selected}
    >
      <SelectorInfo>
        <SelectorInfoName>
          {props.pilot.name}
        </SelectorInfoName>
        <SelectorInfoDescription>
          {props.selected ? 'Pilot flying' : 'Pilot Monitoring'}
        </SelectorInfoDescription>
      </SelectorInfo>
      <HistoryRoute to={traineeProfile(props.pilot.id)}>
        View History
      </HistoryRoute>
    </SelectedButton>
  );
};

const SelectorInfoDescription = styled.div`
    color: ${props => props.theme.lightGrey.toString()};
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    text-align: left;
    letter-spacing: 0.75px;
    text-transform: uppercase;
`;

const SelectedButton = styled.button<{ selected?: boolean }>`
    position: relative;
    display: flex;
    cursor: pointer;
    width: calc(50% - 6px);
    border-radius: 4px;
    background: ${props => (props.selected
    ? props.theme.primary.toString()
    : props.theme.mediumLightGrey.toString())};
    padding: 8px 102px 8px 8px;
    border: transparent;
    ${media.xs} {
        width: 100%;
    }

  ${SelectorInfoDescription}{
    color: ${props => (props.selected ? props.theme.white.toString() : props.theme.lightestGrey.toString())};
  }
`;

const SelectorInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 100%;
`;
const HistoryRoute = styled(NavLink)`
    position: absolute;
    top: 50%;
    right: 12px;
    color: ${props => props.theme.accent.string()};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    transform: translateY(-50%);
`;
const SelectorInfoName = styled.div`
    color: ${props => props.theme.white.toString()};
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export default TraineeTabs;
