// Grid containers.
import styled from 'styled-components';
import { black } from '@/theme/palette';

export const FormColumn = styled.div<{ padding?: string, width?: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  min-width: ${props => props.width || '200px'};
  padding: ${props => props.padding || '1em'};
`;

export const InputLabel = styled.label`
  color: ${props => props.theme.textColor.string()};
`;

export const InputField = styled.input<{ as?: string, height?: string, resize?: string }>`
  appearance: none;
  background-color: transparent;
  border: 1px solid ${props => props.theme.textColor.darken(0.4).string()};
  border-radius: ${props => props.theme.borderRadius};
  box-sizing: border-box;
  color: ${props => props.theme.textColor.string()};
  text-align: left;
  transition: all ${props => props.theme.transitionTime};
  margin: 10px 0 10px 0;
  height: 50px;
  padding-inline: 12px;

  &:disabled {
    border-color: ${props => props.theme.textColor.darken(0.7).string()};
    color: ${props => props.theme.textColor.darken(0.4).string()};
  }

  &:active,
  &:hover {
    border-color: ${props => props.theme.textColor.string()};
  }

  // Textarea-specific options
  ${props => (props.height ? `min-height: ${props.height};` : '')};
  ${props => (props.resize ? `resize: ${props.resize};` : '')};

  option{
    color: ${props => props.theme.background.toString()};
  }

  &::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
`;

export const InputGroupFields = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const FieldErrorMessage = styled.div`
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  color: ${props => props.theme.errorColor.string()};
  font-size: 16px;
  bottom: -2px;
`;

export const FormRow = styled.div<{ $flexGrow?: number, padding?: string }>`
  display: flex;
  align-items: center;
  flex: ${props => `${props.$flexGrow} 1 1`} ;
  padding: ${props => props.padding || '1em'};
`;

FormRow.defaultProps = {
  $flexGrow: 1,
};
