import * as React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Input, InputWrapper } from '@/ui/components/Input';
import { Button } from '@/ui/Button';
import LoginLogo from '@/assets/logo/Logo.png';
import { useAppDispatch, useAppSelector } from '@/store';
import backgroundImage from '@/assets/BG_axis.png';
import userIcon from '@/assets/icons/user.svg';
import lockIcon from '@/assets/icons/lock.svg';
import config from '../../../config';
import { sanitizeUsername } from '../../utils';
import * as actions from '../../actions';

type Props = {
  error?: string,
  isPreview?: boolean,
};

const Login = ({ isPreview, error }: Props) => {
  const auth = useAppSelector(state => state.auth);
  const dispatch = useAppDispatch();
  const isAuthenticating = auth.get('isAuthenticating');
  const onAuthenticate = (username: string, password: string) => dispatch(actions.authenticate(username, password));
  const [sanitizedUsername, setSanitizedUsername] = React.useState('');

  const handleLogin = (event: React.FormEvent) => {
    event.preventDefault();

    if (typeof onAuthenticate !== 'function') {
      return;
    }

    const target = event.target as HTMLFormElement;
    const username = target.username.value;
    const password = target.password.value;
    // const isAdmin = target.isAdmin.checked;

    if (username.length < 1 || password.length < 1) {
      return;
    }

    onAuthenticate(username, password);
  };

  const handleChange = (event: React.ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    setSanitizedUsername(sanitizeUsername(target.value));
  };
  const handleReloadPage = () => window.location.reload();

  return (
    <PageBackground>
      <Page>
        <Header>
          <LogoWrapper onClick={handleReloadPage}>
            <Logo />
          </LogoWrapper>
        </Header>

        <Form onSubmit={handleLogin} noValidate>
          {error && <ErrorMessage>{error}</ErrorMessage>}

          <InputWrapper>
            <User />
            <Input
              name="username"
              type="email"
              placeholder="Username"
              value={sanitizedUsername}
              onChange={handleChange}
              disabled={isAuthenticating}
            />
          </InputWrapper>

          <InputWrapper>
            <Lock />
            <Input
              name="password"
              type="password"
              placeholder="Password"
              disabled={isAuthenticating}
              required
            />
          </InputWrapper>

          <Button
            disabled={isAuthenticating}
            width="320px"
            $margin="0px"
            $marginTop="16px"
          >
            {isAuthenticating ? 'AUTHENTICATING...' : 'LOGIN'}
          </Button>

        </Form>

        {isPreview && <PreviewNotice>Not For Training</PreviewNotice>}

        <Footer>
          <FooterTitle>
            Powered by InstructIQ®
          </FooterTitle>
          <Copyright>
            &copy;
            {moment().format(' Y ')}
            Axis Flight Simulator. All Rights Reserved.
          </Copyright>
        </Footer>
      </Page>
    </PageBackground>
  );
};

export default Login;

Login.defaultProps = {
  error: '',
  isPreview: config.deployEnv === 'preview',
};

// Supporting components
const PageBackground = styled.div`
  background: center center no-repeat url('${backgroundImage}');
  background-size: cover;
  font-family: 'Heebo', sans-serif;
  height: 100vh;
  width: auto;
`;

const Page = styled.main`
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

const Header = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 8vh;
`;

const Logo = styled.div`
  background: center center transparent no-repeat url('${LoginLogo}');
  background-size: contain;
  display: inline-block;
  height: 96px;
  width: 228px;
`;

const LogoWrapper = styled.button`
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 32px 48px;
  box-sizing: border-box;
  border: 1px solid #1D242F;
  background: rgba(29, 36, 47, 0.70);
  border-radius: 8px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

const PreviewNotice = styled.div`
  flex-grow: 1;
  font-size: 1.3em;
  text-align: center;
  text-transform: uppercase;
`;

const Footer = styled.footer`
  box-sizing: border-box;
  color: ${props => props.theme.textColor.string()};
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 0 auto 32px auto;
  padding: 0 2em;
`;

const Copyright = styled.span`
  font-weight: 400;
  font-size: 10px;
`;

const FooterTitle = styled.span`
  font-size: 24px;
  font-weight: 400;
`;

const ErrorMessage = styled.div`
  background-color: rgba(255, 255, 255, 0.7);
  color: ${p => p.theme.secondary.lighten(0.5).hex()};
  font-weight: bold;
  margin: auto auto 40px;
  padding: 10px 20px;
`;

const User = styled.div`
  background: center center transparent no-repeat url('${userIcon}');
  background-size: contain;
  height: 24px;
  width: 24px;
  margin: auto;
`;

const Lock = styled.div`
  background: center center transparent no-repeat url('${lockIcon}');
  background-size: contain;
  height: 24px;
  width: 24px;
  margin: auto;
`;
