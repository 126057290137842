import { FullSessionType, SessionRunType } from '@/session/constants';
import { isEmpty, orderBy, partition } from 'lodash';
import { ActivityGradesMapType } from '@/summary/constants';
import { timeDiff } from '@/utils/time';
import styled from 'styled-components';
import React, { FC } from 'react';
import dayjs from 'dayjs';
import { Accordion } from '@/components/Accordion';
import { FlightManeuver, FlightManeuverProps } from '@/summary/components/FlightManeuver';

type FlightManeuversProps = {
  run?: SessionRunType,
  sessionId: number,
  activityGrades: ActivityGradesMapType
  session:FullSessionType
}

export const FlightManeuvers: FC<FlightManeuversProps> = ({
  run,
  sessionId,
  activityGrades,
  session,
}) => {
  if (!run || !session) return null;
  const { activities } = run;

  const [
    specifiedActivities,
    notSpecifiedActivities,
  ] = partition(activities, activity => !isEmpty(activity.startedAt) || !isEmpty(activity.endedAt));
  const specifiedActivitiesSorted = orderBy(specifiedActivities, item => dayjs(item.startedAt).unix(), ['asc']);

  return (
    <>
      <FlightManeuversStyles>
        <ManeuverLine />
        {specifiedActivitiesSorted.map(activity => (
          <FlightManeuverTimeline
            key={activity.id}
            activityGrades={activityGrades}
            sessionId={sessionId}
            runId={run.id}
            startTime={session.startedAt}
            activity={activity}
          />
        ))}
      </FlightManeuversStyles>
      {notSpecifiedActivities.length > 0 ? (
        <Accordion key={`non-detected-${run.id}`} title="Non-Detected Maneuvers">
          <FlightManeuverWrapper>
            {notSpecifiedActivities.map(activity => (
              <FlightManeuver
                key={activity.id}
                activityGrades={activityGrades}
                sessionId={sessionId}
                runId={run.id}
                activity={activity}
              />
            ))}
          </FlightManeuverWrapper>
        </Accordion>
      ) : null}

    </>
  );
};

const FlightManeuverTimeline: FC<FlightManeuverProps &{ startTime: string | null}> = ({
  activity,
  startTime,
  activityGrades,
  sessionId,
  runId,
}) => {
  const { startedAt, endedAt } = activity;
  const timeStart = timeDiff(startTime, startedAt);
  const timeEnd = timeDiff(startTime, endedAt);

  return (
    <Maneuver>
      <ManeuverTime>
        <Time>
          {timeStart}
        </Time>
        <Time>
          {timeEnd}
        </Time>
      </ManeuverTime>
      <FlightManeuver
        activityGrades={activityGrades}
        sessionId={sessionId}
        runId={runId}
        activity={activity}
      />
    </Maneuver>
  );
};

const FlightManeuversStyles = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
  margin-bottom: 24px;
`;

const FlightManeuverWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  margin-bottom: 4px;
`;

const Maneuver = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 36px;
  min-height: 54px;

  &:first-of-type, &:last-child {
    &::before {
      position: absolute;
      content: "";
      left: 86px;
      height: calc(100% / 2);
      width: 1px;
      background: ${props => props.theme.background.toString()};
    }
  }

  &:first-of-type::before {
    top: 0;
  }

  &:last-of-type::before {
    bottom: 0;
  }
}
`;

const ManeuverLine = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 86px;
  height: calc(100% - 52px);
  border-left: 1px dashed ${({ theme }) => theme.mediumLightGrey.toString()};

`;
const ManeuverTime = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 36px;
  min-width: 68px;
  text-align: center;

  &::after {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 2px;
    height: 2px;
    background: ${({ theme }) => theme.white.toString()};
    border-radius: 50%;
  }

  &::before {
    position: absolute;
    content: "";
    right: -24px;
    top: 50%;
    transform: translateY(-50%);
    background-image: linear-gradient(
      to bottom,
      ${props => props.theme.primary.string()},
      ${props => props.theme.tertiary.string()}
    );
    border-radius: 50%;
    width: 12px;
    height: 12px;
  }
`;

const Time = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 10px;
`;

export default FlightManeuvers;
