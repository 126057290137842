import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { createPopup } from '@typeform/embed';
import { home, schedule, userGuide } from '@/utils/routes';
import AxisLogo from '@/assets/logo/AxisLogo.svg';
import homeSVG from './svg/icon-home.svg';
import logoutSVG from './svg/icon-logout.svg';
import sessionsSVG from './svg/icon-sessions.svg';
import supportSVG from './svg/icon-support.svg';
import userguideSVG from './svg/icon-userguide.svg';
import '@typeform/embed/build/css/popup.css';

type Props = {
  showNavBar: boolean,
  handleLogout?: Function,
  onHideNavBar: () => void,
};

const NavBar = ({ showNavBar, handleLogout, onHideNavBar }: Props) => {
  const onLogout = () => {
    if (handleLogout) {
      handleLogout();
    }
  };

  const typeformPopup = createPopup(
    'https://mikhailklassen.typeform.com/to/YkdIGe',
    {
      autoClose: 3,
      hideHeaders: true,
      hideFooter: true,
    },
  );

  const onHandleHideNavBar = () => {
    onHideNavBar();
  };

  return (
    <>
      <NavWrapper open={showNavBar}>
        <Wrapper>
          <Logo />
          <NavLink to={home()}>
            <NavItem>
              <Icon icon={homeSVG} />
              Home
            </NavItem>
          </NavLink>
          <NavLink to={schedule()}>
            <NavItem>
              <Icon icon={sessionsSVG} />
              Sessions
            </NavItem>
          </NavLink>
          <NavItem onClick={() => typeformPopup.open()}>
            <Icon icon={supportSVG} />
            Contact Us
          </NavItem>
        </Wrapper>
        <Wrapper>
          <NavItem onClick={onLogout}>
            <Icon icon={logoutSVG} />
            Log Out
          </NavItem>
        </Wrapper>
      </NavWrapper>
      <Background hidden={!showNavBar} onClick={onHandleHideNavBar} />
    </>
  );
};

export default NavBar;

const NavWrapper = styled.div<{ open: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  height: calc(100% - 64px);
  width: calc(40vw - 50px);
  background-color: ${props => props.theme.background.toString()};
  z-index: 13;
  padding: 32px 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 18px;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
  transition: transform 0.3s ease-in-out;
  display: ${({ open }) => (open ? undefined : 'none')};
`;

const Wrapper = styled.div`
`;

const Logo = styled.div`
  background: center center transparent no-repeat url('${AxisLogo}');
  background-size: contain;
  height: 51px;
  width: 188px;
  margin-bottom: 32px;
`;

const NavItem = styled.div`
  display: flex;
  margin: 22px 0;
  color: white;
`;

const Icon = styled.div<{ icon: string }>`
  height: 29px;
  width: 25px;
  background: center center transparent no-repeat url('${props => props.icon}');
  margin-right: 20px;
`;

const Background = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: ${props => props.theme.background.toString()};
  opacity: 0.4;
  z-index: 12;
`;
