import { Map } from 'immutable';
import { GradeLevelType } from '../../summary/constants';
import { SessionEventType } from '../../session/constants';
import { FrameType } from '../../frames/constants';
import {
  MetadataAnnotation,
  NormSummaryAnnotation,
  NormSummaryBannerAnnotation,
  WaypointAltAnnotation,
  WaypointGroundAnnotation,
  WaypointLateralAnnotation,
  WaypointMaxCasAnnotation,
} from '../plotly/annotations/types';

export const TYPE_PLOTLY = 'plotly';
export const TYPE_SUMMARY = 'summary';
export const TYPE_SUMMARY_PLOTS = 'summary woth plots';
export const TYPE_STEEP_TURN_SVG = 'steep turn svg';
export const TYPE_BANK_TURN_SVG = 'bank turn svg';
export const TYPE_LANDING_SVG = 'landing svg';
export const TYPE_APPROACH = 'approach svg';
export const TYPE_COCKPIT_PREPARATION = 'cockpit preparation';
export const TYPE_REJECTED_TAKEOFF_SVG = 'rejected takeoff svg';
export const TYPE_DEPARTURE = 'departure';

export type VisualizationType =
  | typeof TYPE_PLOTLY
  | typeof TYPE_SUMMARY
  | typeof TYPE_SUMMARY_PLOTS
  | typeof TYPE_STEEP_TURN_SVG
  | typeof TYPE_BANK_TURN_SVG
  | typeof TYPE_LANDING_SVG
  | typeof TYPE_APPROACH
  | typeof TYPE_REJECTED_TAKEOFF_SVG
  | typeof TYPE_DEPARTURE
  | typeof TYPE_COCKPIT_PREPARATION;

type PlotlyVisualizationType = {
  data: Object[];
  layout: {
    annotations: Object[];
    legend: Object;
    height: number;
    shapes: Object[];
    width: number;
  };
};

type SvgAndNormVisualizationType = Object;

export type NormVisualizationType = {
  name: string;
  pass: boolean | null;
  ordering: number;
};

export type NormType = {
  activityCode: string;
  activityName: string;
  normCode: string;
  score: number | null;
  pass: boolean | null;
  evidence: NormEvidenceObject | number | null;
  envelope: Object | null | any;
  annotation?: NormSummaryAnnotation[]
};

export type NormEvidenceObject = {
  distToRwyThld: number;
  measure: number;
  engineStart?: {
    timestamp: string
  };
  sessionStart?: {
    timestamp: string
  };
  vr?: number;
  v2?: number;
};

export type NormSummaryType = {
  payload: NormType[];
  layout?: Object;
  expand: boolean;
};

type AnyVisualizationType =
  | PlotlyVisualizationType
  | SvgAndNormVisualizationType
  | NormSummaryType

export type VisualizationDefinitionType<TMeta extends Object | void = void> = {
  type: VisualizationType;
  data: AnyVisualizationType;
  page?: string;
  metaData?: VisualizationMetaDataType<TMeta>;
};

export type ApproachInsightDataPoint = {
  altMsl: number;
  distanceFromThreshold: number;
  latDev: number;
  windSpeed?: number;
  windDirection?: number;
  relWindDirection?: number;
};
export type ApproachInsightPlot = {
  airport: string;
  data: ApproachInsightDataPoint[];
  fafDistance: number;
  fafElevation: number;
  runway: string;
  thresholdCrossingHeight: number;
  thresholdCrossingIndex: number;
  tdze: number;
};
export type ApproachInsightType = {
  summary?: NormSummaryType;
  approach?: ApproachInsightPlot;
};

export type GoAroundInsightType = {
  summary?: NormSummaryType;
  approach?: ApproachInsightPlot;
};

export type BaseTurnsInsightPayloadType = {
  direction: number;
  initialHdg: number;
  finalHdg: number;
  finalHdgGrade: GradeLevelType;
  initialAlt: number;
  maxAlt: number;
  maxAltGrade: GradeLevelType;
  minAlt: number;
  minAltGrade: GradeLevelType;
  initialIas: number;
  maxIas: number;
  maxIasGrade: GradeLevelType;
  minIas: number;
  minIasGrade: GradeLevelType;
  averageRoll: number;
  maxRoll: number;
  maxRollGrade: GradeLevelType;
  minRoll: number;
  minRollGrade: GradeLevelType;

  initialCas: number,
  maxCas: number,
  turnMaxCasGrade: number,
  minCas: number,
  turnMinCasGrade: number,
  turnMaxAltGrade: number,
  turnMinAltGrade: number,
  turnMaxRollGrade: number,
  turnMinRollGrade: number,
  turnFinalHdgGrade: number,
}

export type BaseTurnsInsightType = {
  summary?: NormSummaryType;
  payload?: Array<BaseTurnsInsightPayloadType>;
};

export type CockpitPreparationInsightType = {
  // Need to satisfy other insight types
  summary: undefined,
  annotation?: {
    engineStartTime?: NormSummaryAnnotation[]
  };
};

export type CockpitPreparationMetaType = {
  banner: NormSummaryBannerAnnotation,
  data: {
    title?: string,
    description?: string,
    type?: 'bar' | 'bar-line',
    time?: number,
    color?: string,
  }[]
};

export type DepartureInsightType = {
  // Need to satisfy other insight types
  summary: undefined,
  annotation?: {
    lateralDistFromWaypoint: [MetadataAnnotation, WaypointLateralAnnotation]
    maxAltAtWaypoint: [MetadataAnnotation, WaypointAltAnnotation]
    minAltAtWaypoint: [MetadataAnnotation, WaypointAltAnnotation]
    transitionGndTrackDeviation: [MetadataAnnotation, WaypointGroundAnnotation]
    transitionMaxCas: [MetadataAnnotation, WaypointMaxCasAnnotation]
  }[];
  departure: {
    airport_code: string,
    runway: {
      latitude: number,
      longitude: number,
      trueHeading: number,
      name: string
    },
    sid: {
      name: string
      waypoints: Array<any>,
      transitions: Array<{
        name:string,
        id: number,
        max_altitude: number | null,
        min_altitude: number | null,
        is_turn: boolean,
        magneticCourse: number | null,
        fix: {
          fix_id: number,
          name: string,
          latitude: number,
          longitude: number
        },
        sid: number,
        sequenceNumber: number
        maxAirspeed: number | null
      }>
    }
  }
};

export type SteepTurnInsightType = BaseTurnsInsightType;

export type BankTurnInsightType = BaseTurnsInsightType;

export type GearRetractionInsightValueType = {
  altMsl: number;
  timestamp: string;
};

export type AirspeedNormType = {
  percentile_5: number[];
  percentile_25: number[];
  median: number[];
  percentile_75: number[];
  percentile_95: number[];
  timestamp: string[];
};

export type GroundRollColNormType = {
  percentile_5: number[];
  percentile_25: number[];
  percentile_75: number[];
  percentile_95: number[];
  median: number[];
  timestamp: string[];
};

export type PitchNormType = {
  percentile_5: number[];
  percentile_25: number[];
  percentile_75: number[];
  percentile_95: number[];
  median: number[];
  timestamp: string[];
};

export type LateralAccNormTypeNormType = {
  percentile_5: number[];
  percentile_25: number[];
  percentile_75: number[];
  percentile_95: number[];
  median: number[];
  timestamp: string[];
};

export type GroundRollHeadingNormType = {
  timestampEnd: string;
  timestampStart: string;
  trueHeading: number;
  sd: number;
};
export type GearRetractionNormType = {
  gearLvr: GearRetractionInsightValueType;
  mean: GearRetractionInsightValueType;
  median: GearRetractionInsightValueType;
  percentile_5: GearRetractionInsightValueType;
  percentile_25: GearRetractionInsightValueType;
  percentile_75: GearRetractionInsightValueType;
  percentile_95: GearRetractionInsightValueType;
};

type BeaconType = {
  name: string;
  lat: number;
  lon: number;
};

export type CasMarkerType = {
  cas: number;
  position: number;
};

export type LandingNormType = {
  position: number;
  pass: boolean;
  isActive?: 'True' | 'False' | '';
};

export type RejectedTakeoffNormType = {
  position: number;
  pass: boolean;
  normCode: string;
};

type LandingNormObjectType = {
  [key: string]: LandingNormType;
};

type LandingDataType = {
  runwayName: string;
  oppositeRunwayName: string;
  airportCode: string;
  runwayLength: number;
  runwayWidth: number;
  trajectoryEnd: number;
  windDirection: number;
  relWindDirection: number;
  windSpeed: number;
  casMarkers: CasMarkerType[];
  norms: LandingNormObjectType;
  runwayDisplacedThreshold: number;
  oppositeRunwayDisplacedThreshold: number;
  latDev: [number, number][];
};

export type SIDDepartureInsightType = {
  data: FrameType[];
  runway: string;
  airport: string;
  runwayLat: number;
  runwayLon: number;
  runwayLat_2: number;
  runwayLon_2: number;
  beacons: BeaconType[];
};

export type RejectedTakeoffDepartureType = {
  oppositeRunwayDisplacedThreshold: number;
  oppositeRunwayName: string;
  relWindDirection: number;
  runwayDisplacedThreshold: number;
  runwayLength: number;
  runwayName: string;
  runwayWidth: number;
  trajectoryEnd: number;
  windDirection: number;
  windSpeed: number;
};

export type TakeoffInsightType = {
  summary?: NormSummaryType;
  departure?: SIDDepartureInsightType;
};

export type LandingInsightType = {
  summary?: NormSummaryType;
  landing?: LandingDataType;
};

export type RejectedTakeoffInsightType = {
  summary?: NormSummaryType;
  departure?: RejectedTakeoffDepartureType;
};

export type InsightType =
  | ApproachInsightType
  | SteepTurnInsightType
  | BankTurnInsightType
  | TakeoffInsightType
  | LandingInsightType
  | CockpitPreparationInsightType
  | DepartureInsightType
  | RejectedTakeoffInsightType;

export type InsightDataMapType = Map<string, string | null>;

export type InsightResponseType = {
  activityCode: string | null;
  ksaComponentCode: string | null;
  insight: InsightType;
};

export type VisualizationDataType<T> = {
  frames: FrameType[];
  insight: T | null;
};

export type VisualizationMetaDataType<TMeta extends Object | void = void> = {
  aircraftType: string | null | undefined;
  aircraftDesignator: string;
  trainingCenter: string;
  flapSettings: number[];
  equipmentId?: string;
} & TMeta;

export type VisualizationHandlerType<T extends InsightType | void, TMeta extends Object | void = void> = (
  activityCode: string,
  data: VisualizationDataType<T>,
  markers: SessionEventType[],
  metaData: VisualizationMetaDataType<TMeta>,
  params?: Array<string | string[]>
) => VisualizationDefinitionType<TMeta>[];

export const CATEGORY_AIR = 'IN-AIR';
export const CATEGORY_APPROACH = 'APPROACH';
export const CATEGORY_APPROACH_TO_STALL = 'APPROACH_TO_STALL';
export const CATEGORY_AUTOROTATION = 'AUTOROTATION';
export const CATEGORY_AUTOROTATION_LANDING = 'AUTOROTATION_LANDING';
export const CATEGORY_ENGINE_START = 'ENGINE_START';
export const CATEGORY_GO_AROUND = 'GO_AROUND';
export const CATEGORY_GROUND = 'GROUND';
export const CATEGORY_LANDING = 'LANDING';
export const CATEGORY_LOW_AIRSPEED = 'LOW_AIRSPEED';
export const CATEGORY_MISSED_APPROACH = 'MISSED_APPROACH';
export const CATEGORY_QUICKSTOP = 'QUICKSTOP';
export const CATEGORY_NONE = 'NONE';
export const CATEGORY_REJECTED_LANDING = 'REJECTED_LANDING';
export const CATEGORY_REJECTED_TAKEOFF = 'REJECTED_TAKEOFF';
export const CATEGORY_SLOPE = 'SLOPE';
export const CATEGORY_STEEP_TURN = 'STEEP_TURN';
export const CATEGORY_COCKPIT_PREPARATION = 'COCKPIT_PREPARATION';
export const CATEGORY_BANK_TURN = 'BANK_TURN';
export const CATEGORY_TAKEOFF = 'TAKEOFF';
export const CATEGORY_TAKEOFF_OEI = 'TAKEOFF_OEI';
export const CATEGORY_TAXI = 'TAXI';
export const CATEGORY_UNUSUAL_ATTITUDE_RECOVERY = 'UNUSUAL_ATTITUDE_RECOVERY';
export const CATEGORY_UPRT = 'UPRT';
export const CATEGORY_STALL = 'STALL';
export const CATEGORY_DEPARTURE = 'DEPARTURE';

export type VisualizationCategory =
  | typeof CATEGORY_AIR
  | typeof CATEGORY_APPROACH
  | typeof CATEGORY_APPROACH_TO_STALL
  | typeof CATEGORY_AUTOROTATION
  | typeof CATEGORY_AUTOROTATION_LANDING
  | typeof CATEGORY_ENGINE_START
  | typeof CATEGORY_GO_AROUND
  | typeof CATEGORY_GROUND
  | typeof CATEGORY_LANDING
  | typeof CATEGORY_LOW_AIRSPEED
  | typeof CATEGORY_MISSED_APPROACH
  | typeof CATEGORY_QUICKSTOP
  | typeof CATEGORY_NONE
  | typeof CATEGORY_REJECTED_LANDING
  | typeof CATEGORY_REJECTED_TAKEOFF
  | typeof CATEGORY_SLOPE
  | typeof CATEGORY_BANK_TURN
  | typeof CATEGORY_STEEP_TURN
  | typeof CATEGORY_COCKPIT_PREPARATION
  | typeof CATEGORY_DEPARTURE
  | typeof CATEGORY_TAKEOFF
  | typeof CATEGORY_TAKEOFF_OEI
  | typeof CATEGORY_TAXI
  | typeof CATEGORY_UNUSUAL_ATTITUDE_RECOVERY
  | typeof CATEGORY_UPRT
  | typeof CATEGORY_STALL;
