import React from 'react';
import { SVGRunway, SVGRunwayCenter, thresholdAreaArrow } from './constants';
import { FixedMarkers, RunwayLines, RunwayThresholdArrow } from './types';

export const getRunwayLines = ({
  x,
  y1,
  y2,
  numberOfLines,
  lineWidth,
  spacing,
  centerSpacing,
}: RunwayLines) => {
  const lines = [];

  let newX = x;

  for (let i = 1; i <= numberOfLines; i++) {
    lines.push(
      <line
        x1={newX}
        x2={newX}
        y1={y1}
        y2={y2}
        stroke="#979797"
        strokeWidth={lineWidth}
        strokeLinecap="square"
        key={`${i} ${newX}`}
      />,
    );

    newX += i === numberOfLines / 2 ? centerSpacing : spacing;
  }

  return lines;
};

export const getRunwayCenterLine = ({
  x,
  y1,
  y2,
  length,
  margin,
}: {
  x: number;
  y1: number;
  y2: number;
  length: number;
  margin: number;
}) => {
  const lines = [];
  const count = Math.ceil((y2 - y1) / (length + margin * 2));

  for (let i = 0; i <= count; i++) {
    const start = y1 + i * (length + margin) + margin;
    const end = start + length;
    lines.push(
      <line
        x1={x}
        x2={x}
        y1={start}
        y2={end}
        stroke="#979797"
        strokeLinecap="square"
        key={`${i} ${start}`}
      />,
    );
  }

  return lines;
};

export const getFixedMarkers = ({ x1, x2, y1, y2, width }: FixedMarkers) => (
  <>
    <line
      x1={x1}
      x2={x1}
      y1={y1}
      y2={y2}
      stroke="#979797"
      strokeWidth={width}
      strokeLinecap="square"
      key={`left ${y1}`}
    />
    <line
      x1={x2}
      x2={x2}
      y1={y1}
      y2={y2}
      stroke="#979797"
      strokeWidth={width}
      strokeLinecap="square"
      key={`right ${y1}`}
    />
  </>
);

const drawSvgArrowDown = ({ x, y, length }: RunwayThresholdArrow) => {
  const id = `${x}-${y}-arrow-down`;

  return (
    <g key={id}>
      <defs>
        <marker
          id={`${id}-arrowhead`}
          viewBox="0 0 5 5"
          markerWidth="3"
          markerHeight="3"
          refX="2.5"
          refY="2.5"
          orient="auto"
          stroke="#fff"
          fill="#fff"
        >
          <path d="M 0 0 L 5 2.5 L 0 5 z" />
        </marker>
      </defs>
      <line
        x1={SVGRunwayCenter}
        x2={SVGRunwayCenter}
        y1={y}
        y2={y + length}
        stroke="#fff"
        strokeWidth="1"
        markerEnd={`url(#${`${id}-arrowhead`})`}
      />
    </g>
  );
};

const drawSvgArrowUp = ({ x, y, length }: RunwayThresholdArrow) => {
  const id = `${x}-${y}-arrow-up`;

  return (
    <g key={id}>
      <defs>
        <marker
          id={`${id}-arrowhead`}
          viewBox="0 0 5 5"
          markerWidth="3"
          markerHeight="3"
          refX="2.5"
          refY="2.5"
          orient="auto"
          stroke="#fff"
          fill="#fff"
        >
          <path d="M 5 0 L 0 2.5 L 5 5 z" />
        </marker>
      </defs>
      <line
        x1={SVGRunwayCenter}
        x2={SVGRunwayCenter}
        y1={y}
        y2={y + length}
        stroke="#fff"
        strokeWidth="1"
        markerStart={`url(#${`${id}-arrowhead`})`}
      />
    </g>
  );
};

export const getTopThresholdAreaArrows = (thresholdAreaHeight: number) => {
  const arrows: RunwayThresholdArrow[] = [];
  const arrowsCount = Math.floor(
    (thresholdAreaHeight - 2 * thresholdAreaArrow.margin)
      / thresholdAreaArrow.height,
  );

  for (let i = 0; i < arrowsCount; i++) {
    arrows.push({
      x: SVGRunwayCenter,
      y:
        SVGRunway.y0
        + thresholdAreaArrow.margin * (i + 1)
        + thresholdAreaArrow.height * i,
      length: thresholdAreaArrow.height,
    });
  }

  return (
    <g key="topThresholdArrows">
      {arrows.map(arrow => drawSvgArrowDown(arrow))}
    </g>
  );
};

export const getBottomThresholdAreaArrows = (
  y: number,
  thresholdAreaHeight: number,
  offset: number = 0,
) => {
  const arrows: RunwayThresholdArrow[] = [];
  const arrowsCount = Math.floor(
    (thresholdAreaHeight - 2 * thresholdAreaArrow.margin)
      / (thresholdAreaArrow.height + thresholdAreaArrow.margin),
  );

  for (let i = 0; i < arrowsCount; i++) {
    arrows.push({
      x: SVGRunwayCenter,
      y:
        y
        + offset
        + thresholdAreaHeight
        - thresholdAreaArrow.margin
        - (thresholdAreaArrow.margin * (i + 1) + thresholdAreaArrow.height * i),
      length: thresholdAreaArrow.height,
    });
  }

  return (
    <g key="bottomThresholdArrows">
      {arrows.map(arrow => drawSvgArrowUp(arrow))}
    </g>
  );
};

export const getNormalizers = (
  runwayLength: number,
  runwayWidth: number,
  runwayDisplacedThreshold: number = 0,
  oppositeRunwayDisplacedThreshold: number = 0,
) => {
  const totalRunwayLength = runwayLength + runwayDisplacedThreshold + oppositeRunwayDisplacedThreshold;
  const normalizeFactor = SVGRunway.height / totalRunwayLength;
  const normalizeFactorX = SVGRunway.width / runwayWidth;
  const normalizedBottomThreshold = runwayDisplacedThreshold * normalizeFactor;
  const normalizedTopThreshold = oppositeRunwayDisplacedThreshold * normalizeFactor;

  const getPosition = (value: number) => {
    const invertedValue = runwayLength - value;
    const valueWithOffset = invertedValue + oppositeRunwayDisplacedThreshold;
    const normalizedPosition = valueWithOffset * normalizeFactor;

    return normalizedPosition;
  };

  return {
    normalizeFactor,
    normalizeFactorX,
    normalizedBottomThreshold,
    normalizedTopThreshold,
    getPosition,
  };
};

export const getAirportCode = (airportCode: string) => (
  <text
    fontFamily="Heebo"
    fontSize="18"
    fontWeight="bold"
    fill="white"
    x="1000"
    y="15"
  >
    {airportCode}
  </text>
);
