import * as Plotly from 'plotly.js';
import { PlotData } from 'plotly.js';
import { CockpitPreparationMetaType } from '@/utils/visualization/constants';
import { getMinutes } from '@/utils/time';
import { lightestGrey, mediumLightGrey, white } from '@/theme/palette';
import { useMemo } from 'react';
import { PlotlyOptions } from '../types';

const SVG_MARGIN = {
  l: 30,
  r: 48,
  t: 42,
  b: 68,
};

const SVG_HEIGHT = 400;
const BAR_HEIGHT = 16;

type CockpitPlotOptionsType = {
  plotData: CockpitPreparationMetaType['data'],
}

type ChartValuesType = {
  shapes: Partial<Plotly.Shape>[],
  yValues: string[];
  xValues: number[];
  xValuesColors: string[];
  maxTime: number;
}

function splitStringWithBreak(text: string): string {
  const words: string[] = text.split(' ');
  if (words.length <= 1) return text;
  const halfIndex: number = Math.ceil(words.length / 2);
  const firstHalf: string = words.slice(0, halfIndex).join(' ');
  const secondHalf: string = words.slice(halfIndex).join(' ');
  return `${firstHalf} <br /> ${secondHalf}`;
}

const getChartValues = (data: CockpitPreparationMetaType['data']) => data.reduce((acc, {
  time, color = lightestGrey.toString(), title = '', type,
}) => {
  if (type === 'bar-line') {
    return {
      shapes: [...acc.shapes, {
        type: 'line',
        xref: 'x',
        yref: 'paper',
        x0: getMinutes(time),
        y0: 0,
        x1: getMinutes(time),
        y1: 1,
        line: {
          color,
          width: 1,
          dash: 'dash',
        },
      } as Partial<Plotly.Shape>],
      yValues: acc.yValues,
      xValues: acc.xValues,
      xValuesColors: acc.xValuesColors,
      maxTime: Math.max(acc.maxTime, time ? getMinutes(time) : 0),
    };
  }
  return ({
    shapes: acc.shapes,
    yValues: [...acc.yValues, splitStringWithBreak(title)],
    xValues: [...acc.xValues, getMinutes(time)],
    xValuesColors: [...acc.xValuesColors, color ?? ''],
    hovertemplate: 'Total Tested <b>%{base}</b><extra></extra>',
    maxTime: Math.max(acc.maxTime, time ? getMinutes(time) : 0),
  });
}, { yValues: [], xValues: [], xValuesColors: [], maxTime: 0, shapes: [] } as ChartValuesType);

export const getCockpitPlotOptions = ({ plotData }: CockpitPlotOptionsType): PlotlyOptions => {
  const {
    yValues,
    xValues,
    xValuesColors,
    maxTime,
    shapes,
  } = useMemo(() => getChartValues(plotData), [plotData]);

  // Calculate the width fraction
  const widthFraction = (BAR_HEIGHT * plotData.length) / ((SVG_HEIGHT - SVG_MARGIN.t - SVG_MARGIN.b));

  // Prepare the data for Plotly
  const data: Partial<PlotData>[] = [{
    x: xValues,
    y: yValues,
    type: 'bar',
    orientation: 'h',
    width: widthFraction,
    marker: {
      color: xValuesColors,
    },
  }];

  // Calculate the tick interval dynamically
  const desiredTicks = 6; // desired number of ticks

  const tickInterval = Math.ceil(maxTime / desiredTicks);
  // Calculate the buffer dynamically based on the tick interval
  const buffer = tickInterval / 2; // Adjust the factor as needed

  const lastTick = Math.ceil((maxTime + buffer) / tickInterval) * tickInterval;

  const xTicks = [];
  for (let i = 0; i <= lastTick; i += tickInterval) {
    xTicks.push(i);
  }

  const lastTickLine: Partial<Plotly.Shape> = {
    type: 'line',
    xref: 'x',
    yref: 'paper',
    x0: lastTick,
    y0: 0,
    x1: lastTick,
    y1: 1,
    line: {
      color: mediumLightGrey.toString(),
      width: 1,
      dash: 'solid',
    },
  };

  return {
    data,
    layout: {
      annotations: [],
      autosize: true,
      clickmode: 'none',
      dragmode: false,
      hovermode: false,
      margin: SVG_MARGIN,
      paper_bgcolor: 'transparent',
      plot_bgcolor: 'transparent',
      shapes: [
        ...shapes,
        lastTickLine,
      ],
      showlegend: false,
      barcornerradius: '35%',
      xaxis: {
        zeroline: false,
        range: [0, lastTick],
        tickvals: xTicks,
        ticktext: xTicks.map(val => `${val} min`),
        showline: true,
        color: white.toString(),
        gridcolor: mediumLightGrey.toString(),
        gridwidth: 1,
        tickfont: {
          color: white.toString(),
          size: 14,
          family: 'Heebo',
        },
      },
      yaxis: {
        ticksuffix: ' ',
        linecolor: white.toString(),
        automargin: true,
        tickfont: {
          color: white.toString(),
          size: 16,
          family: 'Heebo',
        },
      },
      barmode: 'relative',
    } as unknown as Plotly.Layout,
    config: {
      displayModeBar: false,
      responsive: true,
    },
    style: {
      height: SVG_HEIGHT,
      width: '100%',
      boxSizing: 'border-box',
      fontWeight: 400,
    },
    useResizeHandler: true,
  };
};

export default getCockpitPlotOptions;
