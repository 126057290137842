import { useAppDispatch } from '@/store';
import { ActivityType } from '@/activities/types';
import * as activityActions from '@/activities/actions';
import { ActivityGradesMapType, GRADEABLE_ACTIVITY, GradeLevelType } from '@/summary/constants';
import * as actions from '@/summary/actions';
import { getDisplayGrade, getGradeableGradePair } from '@/summary/utils';
import GradeInput from '@/summary/components/GradeInput';
import ArrowIcon from '@/assets/icons/ArrowIcon.svg';
import styled from 'styled-components';
import { Link, NavLink } from 'react-router-dom';
import React, { FC } from 'react';
import { mediumLightGrey } from '@/theme/palette';

export type FlightManeuverProps = {
  activity: ActivityType,
  sessionId: number,
  runId: number,
  activityGrades: ActivityGradesMapType
}

export const FlightManeuver:FC<FlightManeuverProps> = ({ activity, activityGrades, sessionId, runId }) => {
  const { name, sessionRun, id, note } = activity;
  const dispatch = useAppDispatch();

  const grade = getDisplayGrade(getGradeableGradePair(activityGrades, GRADEABLE_ACTIVITY, id));

  const onSaveNote = (
    activityId: number,
    text: string,
  ) => dispatch(activityActions.saveInstructorNote(activityId, text, note));

  const onUpdateGradeLevel = (
    level: GradeLevelType,
  ) => dispatch(actions.updateInstructorGrade(grade, level, runId, 'activity'));

  return (
    <ManeuverInfo>
      <ManeuverInfoTitle to={`/context/${sessionId}/${sessionRun}/${id}`}>
        {name}
      </ManeuverInfoTitle>
      <ManeuverInfoActions>
        <GradeInput
          key={`grade-${grade.id}`}
          gradeValue={grade.gradeValue}
          isPredicted={grade.isAutomatic}
          activityId={id}
          onUpdateGradeLevel={level => onUpdateGradeLevel(level)}
          onSaveNote={onSaveNote}
          note={note}
          size="small"
        />
        <ArrowWrapperIcon to={`/context/${sessionId}/${sessionRun}/${id}`}>
          <img src={ArrowIcon} alt="arrow icon" />
        </ArrowWrapperIcon>
      </ManeuverInfoActions>
    </ManeuverInfo>
  );
};

const ManeuverInfo = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  background-color: ${props => props.theme.mediumLightGrey.string()};
  border-radius: 4px;
  width: 100%;
  padding: 14px 16px 14px 22px;
  box-sizing: border-box;
`;

const ManeuverInfoTitle = styled(Link)`
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  padding-right: 12px;
  color: ${({ theme }) => theme.white.toString()};
`;

const ManeuverInfoActions = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const ArrowWrapperIcon = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;


  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transform: rotate(90deg);
  }
`;

export default FlightManeuver;
