import * as React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { home } from '../../utils/routes';
import background from '../../assets/BG_axis.png';

export const ResourceNotFound = () => (
  <Wrapper>
    <Title>The resource you&apos;re looking for could not be found.</Title>

    <Message>Sorry for the inconvenience.</Message>

    <br />
    <Link to={home()}>Click to head back to the main page</Link>
  </Wrapper>
);

export default ResourceNotFound;

const Wrapper = styled.main`
  background: transparent center center no-repeat url('${background}');
  background-size: cover;

  font-family: 'Heebo', sans-serif;
  font-size: 1.6rem;
  font-weight: 300;
  text-align: center;

  flex-grow: 1;
  padding: 3rem;
`;

const Title = styled.h1`
  font-family: 'Heebo', sans-serif;
  font-size: 2.7rem;
  font-weight: 300;
`;

const Message = styled.p``;

// @ts-ignore
const Link = styled(NavLink)`
  border-bottom: 2px solid transparent;
  color: ${props => props.theme.textColor.string()};
  font-weight: 400;
  transition: border-bottom-color ${props => props.theme.transitionTime};

  padding-bottom: 4px;

  &:hover {
    color: ${props => props.theme.textColor.string()};
    border-bottom-color: ${props => props.theme.textColor.string()};
  }
`;
