import React from 'react';
import styled from 'styled-components';

import GradeInput from '../GradeInput';
import { GradeableIdType, GradeLevelType, GradingContextType } from '../../constants';
import { ActivitiesController, MarkersController } from './Controllers';

type Props = {
  gradeableId: GradeableIdType,
  gradingContext: GradingContextType,
  instructorGradeLevel?: GradeLevelType | null,
  predictedGradeLevel?: GradeLevelType | null,
  onUpdateGrade: (level: GradeLevelType) => any,
  onSelectDemo: Function,
  isDemo: boolean,
  onChangeActivity: Function;
};

const ContextOverlayNavigation = ({
  predictedGradeLevel,
  onSelectDemo,
  instructorGradeLevel,
  onUpdateGrade,
  gradingContext,
  gradeableId,
  isDemo,
  onChangeActivity,
}: Props) => (
  <>
    <Control width="7rem">
      <ControlBody>
        <GradeInput
          gradeValue={predictedGradeLevel}
          onSelectDemo={onSelectDemo}
          activityId={gradeableId}
          isPredicted
          size="default"
        />
      </ControlBody>

      <ControlTitle>Computed</ControlTitle>
    </Control>

    <Control width="7rem">
      <ControlBody>
        <GradeInput
          gradeValue={instructorGradeLevel}
          onUpdateGradeLevel={onUpdateGrade}
          onSelectDemo={onSelectDemo}
          isDemo={isDemo}
          activityId={gradeableId}
          type="activity"
          size="default"
        />
      </ControlBody>

      <ControlTitle>Instructor</ControlTitle>
    </Control>

    <ActivitiesController
      context={gradingContext}
      gradeableId={gradeableId}
      onChangeActivity={onChangeActivity}
    />

    <MarkersController
      context={gradingContext}
      gradeableId={gradeableId}
      onChangeActivity={onChangeActivity}
    />
  </>
);

ContextOverlayNavigation.defaultProps = {
  instructorGradeLevel: null,
  predictedGradeLevel: null,
};

export const Control = styled.div<{width?: string}>`
  display: inline-block;
  margin: 0 0.4rem 10px, 0.4rem;
  width: ${props => (props.width || 'auto')};
`;

export const ControlBody = styled.div`
  display: table;
  margin: 0 auto;
`;

export const ControlTitle = styled.span`
  display: inline-block;
  font-weight: ${props => props.theme.fontWeightLight};
  text-align: center;
  text-transform: uppercase;

  margin: 0.4rem auto;
  width: 100%;
`;

export default ContextOverlayNavigation;
