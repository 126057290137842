import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import * as actions from '@/competency/actions';
import { useAppDispatch, useAppSelector } from '@/store';
import Loading from '@/ui/Loading';
import { competencyShortNameMapTable, MappedDrilldownObjectType } from '@/competency/constants';
import { CompetencyCode } from '@/competency/types';
import { Header } from '@/components/Header';
import PageHeader from '@/ui/PageHeader';
import { NormType } from '@/utils/visualization/constants';
import styled from 'styled-components';
import { gradeLevel5, gradeLevel9, lightGrey } from '@/theme/palette';
import { Link, useNavigate } from 'react-router-dom';
import { groupBy, keyBy, mapValues, orderBy, sortBy, transform } from 'lodash';
import { Row } from '@/summary/components/TaskList';
import { getBackgroundColor, getBorderColor, InputButtonStylesType } from '@/summary/components/GradeInput';
import { useGradingScheme } from '@/summary/hooks';
import { getGradeValues } from '@/summary/utils';
import { GradeLevelType, GradeType } from '@/summary/constants';
import { Tooltip } from '@/components/Tooltip';
import InfoIcon from '@/assets/icons/InfoIcon.svg';
import { gradingSheet, home, schedule as scheduleRoute, sessionOverview } from '@/utils/routes';
import { ResourceNotFound } from '@/dashboard/components/ResourceNotFound';
import { BreadcrumbListType, Breadcrumbs } from '@/components/Breadcrumbs';
import Body from '@/ui/Body';
import ContentContainer from '@/ui/ContentContainer';

const majorityScore = (array: boolean[]): boolean => {
  const counts = array.reduce((acc, val) => ({
    false: !val ? acc.false + 1 : acc.false,
    true: val ? acc.true + 1 : acc.true,
  }), {
    false: 0,
    true: 0,
  });
  return counts.true > counts.false;
};

const getActivityNames = (
  data: CompetencyDrilldownType[],
) => new Map(Object.entries(mapValues(keyBy(data, 'activityCode'), 'activityName')));

type CompetencyDrilldownType = (NormType & { normName: string, activityId: number });

export const CompetencyPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const gradingScheme = useGradingScheme();
  const gradeValues = getGradeValues(gradingScheme);
  const params = useParams<{ sessionId?: string, runId?: string, competency?: string }>();

  if (!params.sessionId || !params.competency || !params.runId) return <ResourceNotFound />;

  const { competency, sessionId, runId } = params;

  const competencySelector = useAppSelector(state => state.competency);

  const scores = competencySelector.getIn(['scoresByRun', Number(runId)])?.toJS();
  const instructorScores = competencySelector.getIn(['instructorScores', Number(runId)])?.toJS() || {};
  const competencyDrilldown: CompetencyDrilldownType[] = competencySelector.get('drilldown').toJS();
  const isLoadingCompetency = competencySelector.get('isLoadingCompetency');

  const computedScore = scores?.[competency]?.score || null;
  const instructorScore = instructorScores?.[`${competency}Grade`] || null;
  const title = competencyShortNameMapTable.get(competency as CompetencyCode);

  const onComponentMounted = useCallback(() => {
    dispatch(actions.radarPlotMounted(Number(runId)));
    dispatch(
      actions.loadingCompetencyDrilldown(Number(runId), competency!),
    );
  }, [competency, sessionId, runId]);

  useEffect(() => onComponentMounted(), [onComponentMounted]);

  const getCompetencies = useCallback((competencies: CompetencyDrilldownType[]) => {
    if (competencyDrilldown?.length <= 0) return [];
    const activityNames = getActivityNames(competencyDrilldown);

    const mappedCompetencies: MappedDrilldownObjectType = transform(
      groupBy(competencies, 'activityCode'),
      (result: MappedDrilldownObjectType, norms, activityCode) => {
        const initialCompetency = {
          activityName: activityNames.get(activityCode) ?? '',
          activityIds: [] as number[],
          activityNormsMap: {} as Record<string, {
            name: string,
            pass: boolean
          }[]>,
          pass: [] as boolean[],
        };

        const mappedCompetency = norms.reduce((acc, norm) => {
          // Destructure the current norm object for clarity
          const { activityId, normCode, normName, pass } = norm;

          // Add activityId to activityIds array if it's not already present
          if (!acc.activityIds.includes(activityId)) {
            acc.activityIds = [...acc.activityIds, activityId];
          }

          // Initialize the normCode array in activityNormsMap if it doesn't exist
          if (!acc.activityNormsMap[normCode]) {
            acc.activityNormsMap[normCode] = [];
          }

          // Add the norm details to the appropriate normCode array
          acc.activityNormsMap[normCode] = [
            ...acc.activityNormsMap[normCode],
            {
              name: normName,
              pass: Boolean(pass),
            },
          ];

          // Add the pass boolean to the pass array
          acc.pass = [...acc.pass, Boolean(pass)];

          return acc;
        }, initialCompetency);

        const activityNorms = Object.values(mappedCompetency.activityNormsMap).map(activityValue => ({
          name: activityValue.at(0)!.name,
          pass: majorityScore(activityValue.map(value => value.pass)),
        }));
        // eslint-disable-next-line no-param-reassign
        result[activityCode] = {
          activityName: mappedCompetency.activityName,
          activityIds: sortBy(mappedCompetency.activityIds),
          activityNorms: orderBy(activityNorms, ['pass'], ['asc']),
          pass: majorityScore(mappedCompetency.pass),
        };
      },
      {},
    );
    return orderBy(Object.values(mappedCompetencies), ['pass'], ['asc']);
  }, [competencyDrilldown]);

  const onUpdateCompetencyScore = (score: GradeType, level: GradeLevelType) => dispatch(
    actions.updateCompetencyScore(Number(runId), `${competency}Grade`, score, level),
  );

  if (isLoadingCompetency) {
    return <Loading />;
  }

  const competencies = getCompetencies(competencyDrilldown);

  const breadcrumbs:BreadcrumbListType = [
    {
      link: home(),
      title: 'Home',
    },
    {
      title: 'Training Summary',
      link: gradingSheet(sessionId),
    },
    ...(title ? [{
      title,
    }] : []),
  ];

  return (
    <>
      <Header />
      <Body>
        <ContentContainer>
          <Breadcrumbs crumbs={breadcrumbs} />

          {competencies.length >= 1 ? competencies.map(section => (
            <NormsWrapper key={section.activityName}>
              <NormHeading>
                <NormTitle>{section.activityName}</NormTitle>
                <NormInfo>
                  <NormLink to={`/context/${sessionId}/${runId}/${section.activityIds[0]}`}>
                    More Details
                  </NormLink>
                  <LargeDot $pass={section.pass} />
                </NormInfo>
              </NormHeading>
              {section.activityNorms.map(norm => (
                <NormRow key={`norm-${section.activityName}-${norm.name}`}>
                  <LabelColumn>{norm.name}</LabelColumn>
                  <NormColumn>
                    <Dot $pass={norm.pass} />
                  </NormColumn>
                </NormRow>
              ))}
            </NormsWrapper>
          )) : (
            <EmptyContainer>
              <Row>
                <LabelColumn>No norms available.</LabelColumn>
              </Row>
            </EmptyContainer>
          )}
          <ScoreWrapper>
            <ScoreBlock>
              <ScoreList>
                <Score
                  $gradeValue={computedScore}
                  $gradingScheme={gradingScheme}
                  $isDisabled
                  $isPredicted
                >
                  {computedScore ?? '-'}
                </Score>
              </ScoreList>
              <ScoreTitle>
                COMPUTED
              </ScoreTitle>
              <Tooltip hint="Grade computed by the ML models">
                <Icon>
                  <img src={InfoIcon} alt="bar icon" />
                </Icon>
              </Tooltip>
            </ScoreBlock>
            <ScoreBlock>
              <ScoreList>
                {gradeValues.map(gradeValue => {
                  const onClick = () => gradeValue && onUpdateCompetencyScore(instructorScores, gradeValue);
                  const isSelected = instructorScore !== null && instructorScore === gradeValue;

                  return (
                    <Score
                      key={gradeValue}
                      $gradeValue={gradeValue}
                      $gradingScheme={gradingScheme}
                      $isPredicted={!isSelected}
                      onClick={onClick}
                    >
                      {gradeValue}
                    </Score>
                  );
                })}
              </ScoreList>
              <ScoreTitle>
                INSTRUCTOR SCORE
              </ScoreTitle>
              <Tooltip hint="As an instructor you can change score">
                <Icon>
                  <img src={InfoIcon} alt="bar icon" />
                </Icon>
              </Tooltip>
            </ScoreBlock>
          </ScoreWrapper>

        </ContentContainer>
      </Body>
    </>
  );
};

const ScoreWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
  margin-bottom: 40px;
`;

const ScoreBlock = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: ${({ theme }) => theme.mediumGrey.toString()};
  padding: 12px 4px;
  gap: 12px;
  border-radius: 8px;
  overflow: hidden;
`;

export const Score = styled.div<InputButtonStylesType>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => getBackgroundColor(props)};
  border: 1px solid ${props => getBorderColor(props)};
  color: ${props => (props.$isPredicted ? props.theme.textColor.string() : props.theme.background.string())};
  cursor: ${props => (props.$isDisabled ? 'inherit' : 'pointer')};
  text-align: center;
  line-height: 40px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  box-sizing: border-box;
`;

const ScoreList = styled.div`
  display: flex;
  gap: 12px;
`;

const ScoreTitle = styled.div`
  color: ${({ theme }) => theme.lightestGrey.toString()};
  font-size: 12px;
  font-weight: 500;
  text-align: center;
`;

const PageContainer = styled(ContentContainer)`
  margin-top: 158px;
`;

const NormsWrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.mediumGrey.string()};
  margin-bottom: 20px;
  border-radius: 4px;
  overflow: hidden;
`;

const NormHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  color: ${props => props.theme.white.toString()};
  background-image: linear-gradient(
    to bottom,
    ${props => props.theme.primary.toString()},
    ${props => props.theme.tertiary.toString()}
  );
  box-sizing: border-box;
  padding: 16px 24px 16px 16px;
`;
const NormTitle = styled.div`
  color: ${props => props.theme.white.toString()};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;

const NormInfo = styled.div`
  display: flex;
  align-items: center;
`;

const NormLink = styled(Link)`
  display: flex;
  color: ${props => props.theme.white.toString()};
  text-decoration: underline;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  margin-right: 24px;
`;

const NormRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 28px 16px 32px;
  border-bottom: 1px solid ${lightGrey.string()};
  box-sizing: border-box;

  &:last-child {
    border-bottom: none;
  }
`;

const NormColumn = styled.div`
  display: flex;
  width: min-content;
`;

const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
`;

const LabelColumn = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 20px;
`;

const Dot = styled.div<{ $pass: boolean }>`
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: ${props => (props.$pass ? gradeLevel9.string() : gradeLevel5.string())};
`;

const LargeDot = styled.div<{ $pass: boolean }>`
  height: 24px;
  width: 24px;
  background-color: ${props => (props.$pass ? gradeLevel9.string() : gradeLevel5.string())};
  border-radius: 50%;
`;

const Icon = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 12px;
  top: 12px;
  width: 16px;
  height: 16px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export default CompetencyPage;
