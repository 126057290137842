import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { useSelector } from 'react-redux';
import AxisLogo from '@/assets/logo/AxisLogo.svg';

import { logout } from '@/auth/actions';
import { useAppDispatch } from '@/store';
import { SimulatorStatusUtil } from '@/utils/common/simulatorStatusHandler';
import { SimulatorStatus } from '@/training/types';
import { getTrainingCenter } from '@/dashboard/selectors';
import { ETrainingCenter } from '@/types/enums.types';
import { VerticalDivider } from '@/ui/Divider';
import LogoPace from '@/assets/logos/logo-pace.svg';
import LogoLeonardo from '@/assets/logos/logo-leonardo.svg';
import menuIcon from '../../assets/icons/menu.svg';
import NavBar from '../../ui/NavBar';

export const reloadHandler = () => window.location.reload();

type Props = {
  simulatorStatus?: SimulatorStatus | null
};

export const Header = (props: Props) => {
  const dispatch = useAppDispatch();

  const {
    simulatorStatus = null,
  } = props;
  const [showNavBar, setShowNavBar] = React.useState(false);

  const { trainingCenter } = useSelector(getTrainingCenter);
  const onHideNavBar = () => setShowNavBar(!showNavBar);
  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <>
      <NavBar
        showNavBar={showNavBar}
        handleLogout={handleLogout!}
        onHideNavBar={onHideNavBar}
      />
      <HeaderStyles $isSimulatorStatus={!!simulatorStatus}>
        {simulatorStatus && trainingCenter !== ETrainingCenter.LEONARDO && (
          <VerticalBar
            $animate={simulatorStatus === SimulatorStatus.CONNECTED}
            color={SimulatorStatusUtil.getColor(simulatorStatus)}
          >
            {SimulatorStatusUtil.getMessage(simulatorStatus)}
          </VerticalBar>
        )}
        <HeaderWrapper>
          <MenuIcon onClick={() => setShowNavBar(!showNavBar)} />
          <LogoWrapper onClick={reloadHandler}>
            <img src={AxisLogo} alt="Logo icon" />
            {trainingCenter === ETrainingCenter.LEONARDO ? (
              <SubLogo>
                <LeonardoVerticalDivider color="#fff" height={28} width={1} />
                <LogoPaceImg src={LogoPace} alt="Logo icon" />
                <PaceLeonardoSeparator>&</PaceLeonardoSeparator>
                <LogoLeonardoImg src={LogoLeonardo} alt="Logo icon" />
              </SubLogo>
            ) : null}

          </LogoWrapper>
        </HeaderWrapper>
      </HeaderStyles>
    </>
  );
};

export default Header;

const ProgressBarStripesAnimation = keyframes`
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
`;

const VerticalBar = styled.div<{ color: string, $animate: boolean }>`
  background-color: ${props => props.color};
  width: 100%;
  height: 16px;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  z-index: 12;
  ${({ $animate }) => $animate && `
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
  `}
  -webkit-background-size: 1rem 1rem;
  background-size: 1rem 1rem;
  animation-name: ${props => (props.$animate ? ProgressBarStripesAnimation : '')};
  animation-duration: 1s;
  animation-iteration-count: infinite;
`;

const HeaderStyles = styled.header<{ $live?: boolean, $isSimulatorStatus?: boolean }>`
  position: fixed;
  display: flex;
  flex-direction: column;
  min-height: ${props => props.theme.headerHeight};
  background-color: ${props => props.theme.background.string()};
  color: ${props => props.theme.white.string()};
  top: 0;
  box-sizing: border-box;
  width: 100%;
  z-index: 11;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 16px;
  box-sizing: border-box;
`;

const LogoWrapper = styled.button`
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 0 30px 0 0;
  box-sizing: border-box;
`;

const MenuIcon = styled.div`
  background: center center transparent no-repeat url('${menuIcon}');
  background-size: contain;
  display: inline-block;
  height: 30px;
  width: 30px;
`;

const SubLogo = styled.div`
  display: flex;
  align-items: center;
`;

export const PaceLeonardoSeparator = styled.p`
  color: #fff;
  opacity: 0.6;
  font-size: 12px;
  font-style: normal;
  font-weight: 100;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: uppercase;
  margin-inline: 8px;
  margin-block: 0;
`;

export const LogoPaceImg = styled.img`
  width: 78px;
  height: 24px;
  object-fit: contain;
`;

export const LogoLeonardoImg = styled.img`
  width: 101px;
  height: 18px;
  object-fit: contain;
`;

export const LeonardoVerticalDivider = styled(VerticalDivider)`
  margin-inline: 12px;
  margin-block: 0;
`;
