import React from 'react';
import styled from 'styled-components';

import AxisLogo from '@/assets/logo/AxisLogo.svg';
import { reloadHandler } from '@/components/Header';
import LogoSpinnerSVG from './svg/LogoSpinner';
import LogoPulseSVG from './svg/LogoPulse';

export const ANIMATION_SPINNER = 'spinner';
export const ANIMATION_PULSE = 'pulse';

export type LoadingAnimationType =
  typeof ANIMATION_SPINNER |
  typeof ANIMATION_PULSE;

type Props = {
  animation?: LoadingAnimationType,
};

const Loading = (props: Props) => (
  <Wrapper>
    <LoadingHeader>
      <Logo onClick={reloadHandler}>
        <img src={AxisLogo} alt="Logo icon" />
      </Logo>
    </LoadingHeader>
    <LogoWrapper>
      {props.animation === ANIMATION_PULSE
        ? <LogoPulseSVG />
        : <LogoSpinnerSVG />}
    </LogoWrapper>
  </Wrapper>
);

Loading.defaultProps = {
  animation: ANIMATION_PULSE,
};

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${props => props.theme.background.string()};
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 15;
`;

const LoadingHeader = styled.div`
  height: ${props => props.theme.headerHeight};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-inline: 16px;
  box-sizing: border-box;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: 50%;
  width: 50%;
`;
const Logo = styled.div`
    display: flex;
`;

export default Loading;
