import Color from 'color';

// Paladin colours.
const primaryBlue = '#4E9DE6';
const secondaryBlue = '#34639D';

// Main palette
export const primary = Color('#4E9DE6');
export const secondary = Color('#34639D');

export const background = Color('#010D1A');
// Check
export const tertiary = Color('#19608F');
export const quaternary = Color('#16779F');
export const accentBlue = Color('#30AEFF');
export const accentPrimary = Color('#2E3292');
export const accent = Color('#F6BF36');
export const textColor = Color('#ffffff');
export const errorColor = Color('#ff0000');

// Inverse palette
export const inverseBackground = textColor;
export const inverseTextColor = textColor.darken(0.8);

// Grading colours
export const gradeLevelNull = Color('#a1a1a1');
export const gradeLevel1 = Color('#bf0000'); // Previously GRADE_LEVEL_LOWEST
export const gradeLevel2 = Color('#bf2300');
export const gradeLevel3 = Color('#bf4700');
export const gradeLevel4 = Color('#bf6b00');
export const gradeLevel5 = Color('#bf8f00'); // Previously GRADE_LEVEL_LOW
export const gradeLevel6 = Color('#b3bf00');
export const gradeLevel7 = Color('#77bf00');
export const gradeLevel8 = Color('#3bbf00');
export const gradeLevel9 = Color('#00bf00'); // Previously GRADE_LEVEL_HIGH
export const gradeLevel10 = Color('#00bf2f');
export const gradeLevel11 = Color('#00bf5f');
export const gradeLevel12 = Color('#00bf8f');
export const gradeLevel13 = Color('#00bfbf'); // Previously GRADE_LEVEL_HIGHEST
export const gradeLevel14 = Color('#518482');
export const gradeLevel15 = Color('#518482');
export const gradeLevel16 = Color('#518482');
export const gradeLevel17 = Color('#518482');

// Miscellaneous colours
export const selected = Color('#f8a449'); // Orange
export const black = Color('#000000');
export const white = Color('#ffffff');
export const darkGrey = Color('#010D1A');
export const mediumGrey = Color('#0D1623');
export const mediumLightGrey = Color('#182434');
export const lightGrey = Color('#435871');
export const lightestGrey = Color('#4182BF');

// Second Gradient Colors
export const secondGradientStart = Color('#D789D7');
export const secondGradientEnd = Color('#892CDC');

// For backwards compatibility
export default {
  accent,
  accentBlue,
  background,
  black,
  inverseBackground,
  inverseTextColor,
  gradeLevelNull,
  primary,
  secondary,
  selected,
  tertiary,
  quaternary,
  textColor,
  white,
  darkGrey,
  mediumGrey,
  mediumLightGrey,
  lightGrey,
  lightestGrey,
  errorColor,

  // List options
  options: [
    Color(primaryBlue),
    Color(secondaryBlue),
    Color('#a1b152'), // Light green
  ],
};
