import React from 'react';
import styled from 'styled-components';
import { lightestGrey } from '../../theme/palette';

type Props = {
  isDemo: boolean,
  isLiveGrade: boolean | null,
  activityId: number | null,
  onSelectDemo: Function,
};

const DemoButton = ({ isDemo, isLiveGrade, activityId, onSelectDemo }: Props) => {
  const onClick = () => activityId && onSelectDemo(activityId);

  return (
    <Wrapper
      isDemo={isDemo}
      isLiveGrade={isLiveGrade}
      onClick={onClick}
    >
      D
    </Wrapper>
  );
};

export default DemoButton;

type DemoProps = {
  isLiveGrade: boolean | null,
  isDemo: boolean,
};

const getBackground = ({ isLiveGrade, isDemo }: DemoProps): string => {
  switch (true) {
    case isDemo:
      return 'white';

    case isLiveGrade:
      return 'transparent';

    default:
      return 'transparent';
  }
};

const getBorder = ({ isLiveGrade, isDemo }: DemoProps): string => {
  switch (true) {
    case isDemo:
      return 'white';

    case isLiveGrade:
      return lightestGrey.string();

    default:
      return 'white';
  }
};

const Wrapper = styled.div<DemoProps>`
  background-color: ${props => getBackground(props)};
  border: 1px solid ${props => getBorder(props)};
  border-radius: 50%;
  line-height: ${props => (props.isLiveGrade ? '51px' : '41px')};
  color: ${props => (props.isDemo ? props.theme.background.toString() : 'white')};
  cursor: pointer;
  font-weight: ${props => props.theme.fontWeightBold};
  display: flex;
  justify-content: center;
  height: ${props => (props.isLiveGrade ? '51px' : '41px')};
  width: ${props => (props.isLiveGrade ? '51px' : '41px')};
`;
