import React, { ChangeEvent, FormEvent } from 'react';
import { ColumnInput } from '@/components/ColumnInput/ColumnInput';
import styled from 'styled-components';
import { background } from '@/theme/palette';
import { createInstructorAccount, sanitizeUsername } from '../../utils';
import Body from '../../../ui/Body';
import { home } from '../../../utils/routes';
import Title from '../../../ui/head/Title';
import { Button, Wrapper as ButtonWrapper, BUTTON_BORDER_GRADIENT } from '../../../ui/Button';
import ContentContainer from '../../../ui/ContentContainer';
import { useAppDispatch } from '../../../store';
import { logout } from '../../actions';

//  TODO: <InstructorManager> should be rendered by the <App> instead of the <AuthGuard>.

const InstructorManager = () => {
  const dispatch = useAppDispatch();
  const onLogout = (redirectTo?: string) => dispatch(logout(redirectTo));

  const title = 'Instructor Manager';
  const [isLoading, setLoading] = React.useState(false);
  const [sanitizedUsername, setSanitizedUsername] = React.useState('');

  // Submit handler.
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    // Retrieve form data.
    const target = event.target as HTMLFormElement;
    const username = target.username.value;
    const password = target.password.value;
    const confirmation = target.confirmation.value;

    if (username.length < 4) {
      // eslint-disable-next-line no-alert
      alert('Username must be at least 4 characters long.');

      return false;
    }

    // Validate data.
    if (password !== confirmation) {
      // eslint-disable-next-line no-alert
      alert('Passwords must match.');

      return false;
    }

    if (password.length < 4) {
      // eslint-disable-next-line no-alert
      alert('Password must be at least 4 characters long.');

      return false;
    }

    const resetForm = () => {
      setSanitizedUsername('');
      target.password.value = '';
      target.confirmation.value = '';
    };

    // Create instructor account.
    setLoading(true);
    createInstructorAccount(
      username,
      password,
      (error: string) => {
        // eslint-disable-next-line no-alert
        alert(error ? `Could not create instructor account: ${error}` : 'Account created.');

        resetForm();
        setLoading(false);
      },
    );

    return false;
  };

  // Logout handler
  const handleLogout = (event: React.MouseEvent) => {
    event.preventDefault();

    onLogout(home());
  };

  const handleChange = (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;

    setSanitizedUsername(sanitizeUsername(target.value));
  };

  return (
    <>
      <Title title={title} />
      <PageTitle>
        {title}
      </PageTitle>

      <Body>
        <ContentContainer>
          <form onSubmit={handleSubmit}>
            <ColumnInput
              name="username"
              label="Username"
              disabled={isLoading}
              value={sanitizedUsername}
              onChange={handleChange}
            />

            <ColumnInput
              name="password"
              type="password"
              label="Password"
              disabled={isLoading}
            />

            <ColumnInput
              name="confirmation"
              type="password"
              label="Confirm password"
              disabled={isLoading}
            />

            <ButtonWrapper>
              <Button disabled={isLoading} width="180px">
                {isLoading ? 'CREATING ACCOUNT...' : 'CREATE ACCOUNT'}
              </Button>

              <Button
                onClick={handleLogout}
                variation={BUTTON_BORDER_GRADIENT}
                $backgroundColor={background.toString()}
              >
                LOGOUT
              </Button>
            </ButtonWrapper>
          </form>
        </ContentContainer>
      </Body>
    </>
  );
};

const PageTitle = styled.h2`
  width: 100%;
  margin-block: 24px;
  text-align: center;
  font-size: 24px;
  letter-spacing: 2px;
  white-space: nowrap;
`;

export default InstructorManager;
