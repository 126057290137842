import React, { ReactNode, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import styled from 'styled-components';
import { CockpitPreparation } from '@/utils/cockpitPreparation/CockpitPreparation';
import { PlotlyOptions } from '@/utils/plotly/types';
import {
  ApproachInsightType,
  BankTurnInsightType,
  CockpitPreparationMetaType,
  DepartureInsightType,
  InsightType,
  LandingInsightType,
  NormSummaryType,
  TYPE_APPROACH,
  TYPE_BANK_TURN_SVG,
  TYPE_COCKPIT_PREPARATION,
  TYPE_DEPARTURE,
  TYPE_LANDING_SVG,
  TYPE_PLOTLY,
  TYPE_REJECTED_TAKEOFF_SVG,
  TYPE_STEEP_TURN_SVG,
  TYPE_SUMMARY,
  TYPE_SUMMARY_PLOTS,
  VisualizationDataType,
  VisualizationMetaDataType,
} from '@/utils/visualization/constants';
import { SessionEventType } from '@/session/constants';
import { FrameType } from '@/frames/constants';
import { FlattenType } from '@/types/helper.types';
import { DepartureView } from '@/components/DepartureView';
import Plotly from '../../../ui/Plotly';
import getVisualizationsForActivity from '../../../utils/visualization/activity';
import LandingSVG from '../../../ui/svg/Landing';
import WindBoxSVG from '../../../ui/svg/WindBox';
import RejectedTakeoffSVG from '../../../ui/svg/RejectedTakeoff';
import NormSummary from '../../../utils/normSummary';
import { GradingSheetItemType } from '../../constants';
import { useEquipment, useTrainingCenter } from '../../hooks';
import { BankTurnSVG, SteepTurnSVG } from '../../../ui/svg/TurnGraph';

type Props = {
  frames: FrameType[];
  insight: InsightType | null;
  item: GradingSheetItemType;
  markers: SessionEventType[];
};
/*
 TODO: Refactor each key which using "uid()" instead real ID, previously there
   was used index as key, and it breaks render due to React trying to optimize re-rendering
*/

const uid = function () {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
};

const ActivityPlot = ({ insight, frames, item, markers }: Props) => {
  const [position, setPosition] = useState(0);
  const activityCode = item.activityCodes[0] || '';
  const trainingCenter = useTrainingCenter();
  const equipment = useEquipment();
  const equipmentAircraft = equipment
    && equipment.aircraftDetails
    && equipment.aircraftDetails.aircraft
    && equipment.aircraftDetails.aircraft[0];

  const flapSettings = equipmentAircraft?.flapSettings || [];
  const aircraftDesignator = equipmentAircraft?.designator;
  const aircraftType = equipmentAircraft?.type;
  const visualizations = getVisualizationsForActivity(
    activityCode,
    { frames, insight },
    markers,
    {
      aircraftDesignator,
      flapSettings,
      trainingCenter,
      aircraftType,
      equipmentId: equipment.equipmentId,
    },
  );

  const plots: ReactNode[] = visualizations.map(
    ({ type, data: plotData, page, metaData }) => {
      switch (type) {
        case TYPE_PLOTLY: {
          const layout = {
            ...plotData.layout,
            height: 800,
          };

          return (
            <SwipeableWrapper key={uid()}>
              <SubTitle>{page}</SubTitle>
              <Plotly {...plotData} layout={layout} />
            </SwipeableWrapper>
          );
        }

        case TYPE_APPROACH: {
          const layout = {
            ...plotData.layout,
            height: 800,
          };

          if (insight && (insight as ApproachInsightType).approach) {
            const { approach } = insight as ApproachInsightType;

            // eslint-disable-next-line no-unsafe-optional-chaining
            const { windSpeed, windDirection, relWindDirection } = approach?.data[0]!;
            const { rwyContaminantType, massAc, flapLeverPos } = frames[0].data;

            const windBoxData = {
              windSpeed: windSpeed || 0,
              windDirection: windDirection || 0,
              relWindDirection: relWindDirection || 0,
              data: { rwyContaminantType, massAc, flapLeverPos },
            };

            return (
              <SwipeableWrapper key={uid()}>
                <SubTitle>{page}</SubTitle>
                <WindBoxSVG {...windBoxData}>
                  <Plotly {...plotData} layout={layout} />
                </WindBoxSVG>
              </SwipeableWrapper>
            );
          }

          return (
            <SwipeableWrapper key={uid()}>
              <SubTitle>{page}</SubTitle>
              <Plotly {...plotData} layout={layout} />
            </SwipeableWrapper>
          );
        }

        case TYPE_SUMMARY: {
          const data = plotData as NormSummaryType;
          return (
            <SwipeableWrapper key={uid()}>
              <NormSummary
                payload={data.payload}
                expand={data.expand}
                metaData={metaData as VisualizationMetaDataType<{equipmentId:string}>}
              />
            </SwipeableWrapper>
          );
        }

        case TYPE_SUMMARY_PLOTS: {
          const data = plotData as NormSummaryType;
          return (
            <SwipeableWrapper key={uid()}>
              <NormSummary
                payload={data.payload}
                frames={frames}
                expand
              />
            </SwipeableWrapper>
          );
        }

        case TYPE_STEEP_TURN_SVG: {
          return (
            <SwipeableWrapper key={uid()}>
              <SteepTurnSVG {...plotData} />
            </SwipeableWrapper>
          );
        }

        case TYPE_BANK_TURN_SVG: {
          return (
            <SwipeableWrapper key={`${uid()}`}>
              <BankTurnSVG
                data={plotData as unknown as FlattenType<NonNullable<BankTurnInsightType['payload']>>}
              />
            </SwipeableWrapper>
          );
        }

        case TYPE_LANDING_SVG: {
          return (
            <SwipeableWrapper key={uid()}>
              <SubTitle>{page}</SubTitle>
              <LandingSVG {...plotData as VisualizationDataType<LandingInsightType>} />
            </SwipeableWrapper>
          );
        }

        case TYPE_REJECTED_TAKEOFF_SVG: {
          return (
            <SwipeableWrapper key={uid()}>
              <SubTitle>{page}</SubTitle>
              <RejectedTakeoffSVG {...plotData} />
            </SwipeableWrapper>
          );
        }
        case TYPE_COCKPIT_PREPARATION: {
          return (
            <SwipeableWrapper key={uid()}>
              <CockpitPreparation
                plotData={plotData as PlotlyOptions}
                metaData={metaData as CockpitPreparationMetaType}
              />
            </SwipeableWrapper>
          );
        }

        case TYPE_DEPARTURE: {
          return (
            <SwipeableWrapper key={uid()}>
              <DepartureView data={plotData as DepartureInsightType} frames={frames} />
            </SwipeableWrapper>
          );
        }

        default:
          return null;
      }
    },
  );

  const handleChange = (index: number) => {
    setPosition(index);
  };

  return (
    <PlotWrapper>
      <SwipeableViews
        enableMouseEvents
        index={position}
        onChangeIndex={handleChange}
      >
        {plots}
      </SwipeableViews>
      {plots.length > 1
        ? plots.map((_, id: number) => (
          <Position $currentView={position === id} key={uid() + plots.length}>
            &#8226;
          </Position>
        ))
        : ''}
    </PlotWrapper>
  );
};

const PlotWrapper = styled.div`
  width: 100%;
  text-align: center;
`;

const SwipeableWrapper = styled.div`
  width: 100%;
  position: relative;
  margin-top: 15px;
`;
const SubTitle = styled.div`
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 300;
  text-align: left;
  margin-left: 60px;
`;
const Position = styled.span<{ $currentView: boolean }>`
  color: ${props => (props.$currentView
    ? props.theme.textColor.string()
    : props.theme.textColor.darken(0.6).string())};
  font-size: 32px;
  margin: 0 5px;
`;

export default ActivityPlot;
