import moment from 'moment';
import * as React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Select, { components, MultiValue } from 'react-select';
import { useCallback, useEffect, useState } from 'react';
import { ToggleSwitch } from '@/components/ToggleSwitch';
import { Header } from '@/components/Header';
import { BreadcrumbListType, Breadcrumbs } from '@/components/Breadcrumbs';
import Body from '../../../ui/Body';
import Title from '../../../ui/head/Title';
import {
  CreateSessionButton,
  CreateSessionIcon,
  SessionCardRectangle,
} from '../../../ui/SessionCard';
import { createSession, home, sessionOverview } from '../../../utils/routes';
import { Button, BUTTON_TAB, BUTTON_TAB_GRADIENT, Wrapper as ButtonWrapper } from '../../../ui/Button';
import { background, lightestGrey, mediumGrey, white } from '../../../theme/palette';
import Loading from '../../../ui/Loading';
import config from '../../../config';
import userIcon from '../../../assets/icons/loupe.svg';
import ContentContainer from '../../../ui/ContentContainer';

import * as actions from '../../actions';
import * as instructorActions from '../../../instructor/actions';
import { FullSessionType } from '../../../session/constants';
import { useAppDispatch, useAppSelector } from '../../../store';
import { getUserInfo as getUserInfoAction } from '../../../userProfile/slice';

const title = 'Training Sessions';
const customStyles = {
  menu: () => ({
    width: 718,
    paddingTop: 0,
    marginTop: 0,
  }),
  option: (base: any, styles: any) => ({
    ...base,
    height: '100%',
    backgroundColor: mediumGrey.string(),
    color: white.string(),
    fontWeight: '300',
    fontSize: 16,
    ':active': {
      ...styles[':active'],
      backgroundColor: mediumGrey.string(),
      opacity: 0.8,
      color: 'white',
    },
  }),
  input: (base: any) => ({
    ...base,
    color: white.string(),
  }),
  menuList: (styles: any) => ({ ...styles, overflow: 'hidden' }),
  control: (styles: any) => ({
    ...styles,
    backgroundColor: 'rgb(20,74,112)',
    paddingLeft: '5',
    color: white.string(),
    border: '0px solid rgb(20,74,112)',
    opacity: 0.8,
    minHeight: 50,
    ':active': {
      ...styles[':active'],
      border: '0px solid rgb(20,74,112)',
    },
    ':hover': {
      border: '0px solid rgb(20,74,112)',
    },
  }),

  clearIndicator: (styles: any) => ({
    ...styles,
    color: 'white',
    opacity: 0.7,
    ':hover': {
      color: 'white',
    },
  }),

  placeholder: (styles: any) => ({
    ...styles,
    color: 'white',
    fontWeight: '300',
    opacity: 0.7,
    paddingLeft: 10,
    fontSize: 14,
    ':hover': {
      color: 'white',
    },
  }),

  multiValue: (styles: any) => ({
    ...styles,
    opacity: 0.9,
    border: '1px solid black',
    borderRadius: 50,
    marginTop: 7,
    marginBottom: 7,
    backgroundColor: 'rgb(8,30,43)',
  }),

  multiValueLabel: (styles: any) => ({
    ...styles,
    color: 'white',
    opacity: 1,
    paddingLeft: 15,
    fontWeight: '300',
    fontSize: 14,
  }),
  multiValueRemove: (styles: any) => ({
    ...styles,
    color: 'white',
    paddingRight: 8,
    borderRadius: 50,
    ':hover': {
      color: 'white',
    },
  }),
};

const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <Magnifier />
  </components.DropdownIndicator>
);
const NoOptionsMessage = (props: any) => (
  <components.NoOptionsMessage {...props}>
    <span>No More Trainees Available</span>
  </components.NoOptionsMessage>
);

const List = () => {
  // TODO: Refactor this to function base implementation
  let month: string | null = null;
  let previousMonth: string | null = null;

  const upcomingSessions: FullSessionType[] = [];
  const pastSessions: FullSessionType[] = [];
  const currentDate = new Date().toISOString().substr(0, 10);
  const newSessionRoute = createSession();

  const dispatch = useAppDispatch();
  const sessionList = useAppSelector(state => state.sessionList);
  const isLoading = sessionList.get('isLoading');
  const isExtendedLoading = sessionList.get('isExtendedLoading');
  const sessions: FullSessionType[] = sessionList.get('sessions')?.toJS() ?? [];
  const count = sessionList.get('count');

  const [traineesSelected, setSelectedTrainees] = useState<MultiValue<{ value: string, label: string }>>([]);
  const [viewPastSessions, setViewPastSessions] = React.useState(false);
  const [viewAllSessions, setViewAllSessions] = React.useState(false);
  const [allSessionsFetched, setAllSessionsFetched] = React.useState(false);

  const onComponentMounted = useCallback(() => dispatch(actions.sessionListScreenLoaded()), []);
  const getInstructorInfo = () => dispatch(instructorActions.getInstructorInfo());
  const getFilteredSessions = (
    showInstructorSessions: boolean,
  ) => dispatch(actions.getFilteredSessions(showInstructorSessions));

  const getUserInfo = useCallback(() => dispatch(getUserInfoAction()), []);
  const extendSessionList = (
    showInstructorSessions: boolean,
  ) => dispatch(actions.extendSessionList(showInstructorSessions));

  useEffect(() => {
    if (config.isDemo) {
      getUserInfo();
    } else {
      onComponentMounted();
    }
  }, [onComponentMounted, getUserInfo]);

  useEffect(() => setAllSessionsFetched(sessions.length === count), [sessions, count]);

  const filteredSessions = sessions.filter(
    s => {
      if (traineesSelected.length <= 0) return true;
      return traineesSelected.some(o => s.trainees.pilotFlying.name === o.value);
    },
  );

  filteredSessions.forEach(session => {
    if (!session.scheduledFor) {
      return;
    }
    const sessionDate = session.scheduledFor.substr(0, 10);

    // TODO: Refactor to using reduce or useState instead of mutation
    if (currentDate <= sessionDate) {
      upcomingSessions.push(session);
    } else {
      pastSessions.push(session);
    }
  });

  if (isLoading) {
    return <Loading />;
  }

  const sessionsList = viewPastSessions
    ? pastSessions
    : upcomingSessions.reverse();

  const handleClick = (timeFrame: string) => {
    if (timeFrame === 'upcoming' && viewPastSessions) {
      setViewPastSessions(false);
    } else if (!viewPastSessions) {
      setViewPastSessions(true);
    }
  };

  const handleClickToggle = (event: React.ChangeEvent) => {
    setViewAllSessions((event.target as HTMLInputElement).checked);
    getFilteredSessions(viewAllSessions);
  };

  const traineesOption = sessions.map(session => ({
    value: session.trainees.pilotFlying.name,
    label: session.trainees.pilotFlying.name,
  }));

  const handleChange = (selectedOptions: MultiValue<{ value: string, label: string }>) => {
    setSelectedTrainees(selectedOptions);
  };

  const breadcrumbs: BreadcrumbListType = [
    {
      link: home(),
      title: 'Home',
    },
    {
      title,
    },
  ];

  return (
    <>
      <Title title={title} />
      <Header />
      <ContentContainer>
        <BodyWrapper>
          <Breadcrumbs crumbs={breadcrumbs} />

          <TabsWrapper>
            <Button
              onClick={() => handleClick('upcoming')}
              $margin="0.4em"
              variation={viewPastSessions ? BUTTON_TAB : BUTTON_TAB_GRADIENT}
              width="50%"
            >
              UPCOMING
            </Button>

            <Button
              onClick={() => handleClick('past')}
              $margin="0.4em"
              variation={viewPastSessions ? BUTTON_TAB_GRADIENT : BUTTON_TAB}
              width="50%"
            >
              PAST
            </Button>
          </TabsWrapper>

          {!config.isDemo ? (
            <ToggleWrapper>
              <ToggleSwitch
                name="sessionFiltering"
                labelLeft="All Sessions"
                labelRight="My Sessions"
                disabled={false}
                checked={viewAllSessions}
                onChange={handleClickToggle}
              />
            </ToggleWrapper>
          ) : null}
          <SearchBarWrapper>
            <Select
              components={{ DropdownIndicator, NoOptionsMessage }}
              closeMenuOnSelect={false}
              isMulti
              options={traineesOption}
              styles={customStyles}
              placeholder="Filtering Session By Trainee"
              onChange={handleChange}
            />
          </SearchBarWrapper>
          {sessionsList.map(session => {
            const dt = moment(String(session.scheduledFor));
            const courseName = session.courseName || '';
            const lessonName = session.lessonPlanName || '';
            const pilotFlyingName = session.trainees.pilotFlying.name;
            const pilotMonitoringName = session.trainees.pilotMonitoring.name;

            if (month === null || dt.format('MMMM YYYY') !== previousMonth) {
              month = dt.format('MMMM YYYY');
              previousMonth = dt.format('MMMM YYYY');
            } else {
              month = '';
              previousMonth = dt.format('MMMM YYYY');
            }

            return (
              <div key={session.id} data-session-id={session.id}>
                <MonthWrapper>{month}</MonthWrapper>
                <SessionCardRectangle
                  date={dt}
                  buttonRoute={sessionOverview(session.id)}
                  courseName={courseName}
                  lessonName={lessonName}
                  pilotFlying={pilotFlyingName}
                  pilotMonitoring={pilotMonitoringName}
                />
              </div>
            );
          })}

          {viewPastSessions && !allSessionsFetched ? (
            <ButtonWrapper>
              <Button
                onClick={() => extendSessionList(!viewAllSessions)}
              >{isExtendedLoading ? 'LOADING...' : 'SEE MORE'}
              </Button>
            </ButtonWrapper>
          ) : null}

          <CreateSessionButton data-button="create-session" $fixed>
            <NavLink to={newSessionRoute}>
              <CreateSessionIcon />
            </NavLink>
          </CreateSessionButton>
        </BodyWrapper>
      </ContentContainer>
    </>
  );
};

export default List;

const TabsWrapper = styled.div`
  width: 100%;
  z-index: 2;
`;

const BodyWrapper = styled(Body)`
  width: 718px;
  max-width: 100%;
  margin-inline:auto;
`;

const MonthWrapper = styled.div`
  margin: 20px auto 26px auto;
  font-size: 16px;
  color: ${lightestGrey.string()};
  width: 100%;
`;
const SearchBarWrapper = styled.div`
  width: 100%;
  font-size: 16px;
  color: ${lightestGrey.string()};
  box-sizing: border-box;
  margin-bottom: 24px;
`;
const ToggleWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  color: ${lightestGrey.string()};
`;
const Magnifier = styled.div`
  background: center center transparent no-repeat url('${userIcon}');
  background-size: contain;
  height: 23px;
  width: 30px;
  filter: invert(100%) sepia(0%) saturate(7461%) hue-rotate(7deg) brightness(94%) contrast(102%);
`;
