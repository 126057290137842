import React from 'react';
import styled from 'styled-components';

import {
  bottomFixedDistanceMarkers,
  bottomRunwayLines,
  bottomTouchdownZoneMarkings,
  defaultStroke,
  oppositeRunwayNameX,
  oppositeRunwayNameY,
  runwayCenterLine,
  runwayNameX,
  runwayNameY,
  SVGRunway,
  topFixedDistanceMarkers,
  topRunwayLines,
  topTouchdownZoneMarkings,
} from './constants';
import { RunwaySVGProps } from './types';
import {
  getTopThresholdAreaArrows,
  getBottomThresholdAreaArrows,
  getFixedMarkers,
  getRunwayCenterLine,
  getRunwayLines,
  getAirportCode,
} from './utils';

const RunwaySVG = (props: RunwaySVGProps) => {
  const {
    runwayName,
    oppositeRunwayName,
    normalizedBottomThreshold,
    normalizedTopThreshold,
    normalizeFactor,
    runwayLength,
    airportCode,
  } = props;

  const bottomThresholdOffset = SVGRunway.y0
    + runwayLength * normalizeFactor
    + normalizedTopThreshold;

  const bottomThresholdArea = {
    x: SVGRunway.x0,
    y: bottomThresholdOffset,
    width: SVGRunway.width,
    height: normalizedBottomThreshold,
  };

  const topThresholdArea = {
    x: SVGRunway.x0,
    y: SVGRunway.y0,
    width: SVGRunway.width,
    height: normalizedTopThreshold,
  };

  // landing svg scaling test
  // const areasCount = Math.floor(runwayLength / 1000);
  // const normalizedValue = 1000 * normalizeFactor;
  // const areas = [...Array(areasCount).keys()];

  return (
    <RunwayVisualization>
      {airportCode && getAirportCode(airportCode)}

      {/* Opposite Runway Displaced Threshold */}
      {/* Top Threshold */}
      {normalizedTopThreshold > 0 && (
        <rect
          id="topThreshold"
          stroke={defaultStroke.color}
          strokeWidth={defaultStroke.width}
          x={topThresholdArea.x}
          y={topThresholdArea.y}
          width={topThresholdArea.width}
          height={topThresholdArea.height}
        />
      )}

      {normalizedTopThreshold > 0
        && getTopThresholdAreaArrows(
          normalizedTopThreshold,
        )}

      {/* Runway Border */}
      <rect
        id="runway"
        stroke={defaultStroke.color}
        strokeWidth={defaultStroke.width}
        x={SVGRunway.x0}
        y={SVGRunway.y0 + normalizedTopThreshold}
        width={SVGRunway.width}
        height={runwayLength * normalizeFactor}
      />

      {/* landing svg scaling test */}
      {/* {areas.map(x => (
        <rect
          id={`area${x}`}
          key={`area${x}`}
          stroke={defaultStroke.color}
          strokeWidth={defaultStroke.width}
          strokeDasharray="5,5"
          x={SVGRunway.x0 + 1}
          y={SVGRunway.height - normalizedBottomThreshold - (x + 1) * normalizedValue}
          width={SVGRunway.width - 2}
          height={normalizedValue}
        />
      ))} */}

      {/* Runway Displaced Threshold */}
      {/* Bottom Threshold */}
      {normalizedBottomThreshold > 0 && (
        <rect
          id="bottomThreshold"
          stroke={defaultStroke.color}
          strokeWidth={defaultStroke.width}
          x={bottomThresholdArea.x}
          y={bottomThresholdArea.y}
          width={bottomThresholdArea.width}
          height={bottomThresholdArea.height}
        />
      )}

      {normalizedBottomThreshold > 0
        && getBottomThresholdAreaArrows(
          runwayLength * normalizeFactor,
          normalizedBottomThreshold,
          normalizedTopThreshold,
        )}

      {/* Top Runway Lines */}
      {getRunwayLines(
        topRunwayLines(
          normalizedTopThreshold,
          normalizeFactor,
        ),
      )}
      {getRunwayLines(
        topTouchdownZoneMarkings(
          normalizedTopThreshold,
          normalizeFactor,
        ),
      )}
      {getFixedMarkers(
        topFixedDistanceMarkers(
          normalizedTopThreshold,
          normalizeFactor,
        ),
      )}
      {/* todo: fixed distance markers */}
      {/* todo: markers 1500 & 2000 */}

      {/* Bottom Runway Lines */}
      {getRunwayLines(
        bottomRunwayLines(
          runwayLength * normalizeFactor,
          normalizedTopThreshold,
          normalizeFactor,
        ),
      )}

      {/* Bottom Touchdown Lines */}
      {getRunwayLines(
        bottomTouchdownZoneMarkings(
          runwayLength * normalizeFactor,
          normalizedTopThreshold,
          normalizeFactor,
        ),
      )}
      {getFixedMarkers(
        bottomFixedDistanceMarkers(
          runwayLength * normalizeFactor,
          normalizedTopThreshold,
          normalizeFactor,
        ),
      )}
      {/* todo: fixed distance markers */}
      {/* todo: markers 1500 & 2000 */}

      {/* Runway Center Line */}
      {/* todo: scale it */}
      {getRunwayCenterLine(
        runwayCenterLine(normalizedTopThreshold, bottomThresholdOffset),
      )}

      {/* Runway Name */}
      <text
        fontFamily="Heebo"
        fontSize="18"
        fontWeight="bold"
        fill={defaultStroke.color}
      >
        <tspan
          x={runwayNameX(runwayName)}
          y={runwayNameY(
            runwayLength * normalizeFactor,
            normalizedTopThreshold,
            normalizeFactor,
          )}
        >
          {runwayName}
        </tspan>
      </text>

      {/* Opposite Runway Name */}
      <text
        fontFamily="Heebo"
        fontSize="18"
        fontWeight="bold"
        fill={defaultStroke.color}
        transform={`rotate(${180},
            ${oppositeRunwayNameX(oppositeRunwayName)},
            ${
              oppositeRunwayNameY(
                normalizedTopThreshold,
                normalizeFactor,
              ) - 8
            })`}
      >
        <tspan
          x={oppositeRunwayNameX(oppositeRunwayName)}
          y={oppositeRunwayNameY(
            normalizedTopThreshold,
            normalizeFactor,
          )}
        >
          {oppositeRunwayName}
        </tspan>
      </text>
      {props.children}
    </RunwayVisualization>
  );
};

const RunwayVisualization = styled.svg`
  height: 900px;
  width: 100%;
`;

export default RunwaySVG;
