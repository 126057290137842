import styled from 'styled-components';
import * as React from 'react';
import { debounce } from 'lodash';

import { NoteType } from '../activities/types';
import { lightestGrey, mediumGrey } from '../theme/palette';

type Props = {
  onSaveNote: Function,
  note: NoteType | null,
  light?: boolean,
};

const useIsMount = () => {
  const isMountRef = React.useRef(true);
  React.useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};

export const NotesTextArea = ({ onSaveNote, note, light = false }: Props) => {
  const noteText = note !== null ? note.content : '';
  const [currentNote, setCurrentNote] = React.useState(note);
  const [text, setText] = React.useState(noteText);
  const isMount = useIsMount();

  if (note !== currentNote) {
    setCurrentNote(note);
    setText(noteText);
  }

  const debounceSave = React.useRef(debounce((value: string, noteRecord: NoteType | null) => {
    onSaveNote(value, noteRecord);
  }, 2000));

  const handleInputChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
    setText(event.currentTarget.value);
  };

  React.useEffect(() => {
    if (!isMount && text !== noteText) {
      debounceSave.current(text, currentNote);
    }
  }, [isMount, noteText, text, currentNote]);

  return (
    <Note onChange={handleInputChange} placeholder="Add a comment" value={text} $light={light} />
  );
};

const Note = styled.textarea<{ $light?: boolean }>`
  appearance: none;
  background-color: ${props => (props.$light ? mediumGrey.string() : props.theme.background.string())};
  border: none;
  border-radius: 3px;
  box-sizing: border-box;
  color: white;
  width: 100%;
  max-height: 50px;
  font-size: 16px;
  text-align: left;
  padding: 0.7em 1em;
  resize: none;
  margin: ${props => (props.$light ? '' : '15px 0 10px 0')};

  ::-webkit-input-placeholder {
    color: ${lightestGrey.string()};
  }
`;
