import * as React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect } from 'react';

import { home, schedule as scheduleRoute, sessionOverview } from '@/utils/routes';
import { getTrainingCenter } from '@/dashboard/selectors';
import { useAppDispatch, useAppSelector } from '@/store';
import { ETrainingCenter } from '@/types/enums.types';
import { Header } from '@/components/Header';
import { BreadcrumbListType, Breadcrumbs } from '@/components/Breadcrumbs';
import ContentContainer from '@/ui/ContentContainer';
import Body from '@/ui/Body';
import { mediumLightGrey } from '@/theme/palette';
import config from '../../../config';
import Loading from '../../../ui/Loading';
import { FullSessionType, SessionRunType } from '../../constants';
import LessonPlan from '../../../activities/components/LessonPlan';
import { VideoDemoPage } from '../../../demo/pace-leonardo/video';
import * as trainingActions from '../../../training/actions';
import * as actions from '../../actions';
import * as activityActions from '../../../activities/actions';

const LiveTraining = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams<{ sessionId: string }>();
  const { trainingCenter } = useSelector(getTrainingCenter);

  const sessionSelector = useAppSelector(state => state.session);
  const activitiesSelector = useAppSelector(state => state.activities);
  const trainingSelector = useAppSelector(state => state.training);
  const currentSession = sessionSelector.get('currentSession').toJS();
  const currentRunId = activitiesSelector.get('currentRunId');
  const simulatorStatus = trainingSelector.get('simulatorStatus');
  const runs:SessionRunType[] = sessionSelector.get('runs').toJS();

  const isLoading = !currentRunId;
  const onComponentMounted = useCallback((
    sessionId: number,
  ) => dispatch(trainingActions.trainingScreenLoaded(
    sessionId,
  )), []);

  const onEndSessionRun = (
    id: number,
    redirectTo:string,
  ) => dispatch(trainingActions.endSessionRun(id, redirectTo));
  const onDone = () => {
    dispatch(actions.updateRuns([]));
    dispatch(activityActions.clearActivities());
  };

  const redirectToSessionOverview = () => navigate(sessionOverview(parseInt(params.sessionId!, 10)));

  useEffect(() => {
    const sessionId = parseInt(params.sessionId!, 10);

    onComponentMounted(sessionId);
  }, [params.sessionId, onComponentMounted]);

  useEffect(() => {
    if (currentRunId && runs && runs.length) {
      const currentRun = runs.find((run: SessionRunType) => run.id === currentRunId);

      if (currentRun && currentRun.endedAt) {
        // eslint-disable-next-line no-alert
        alert('Current run already ended.');
        redirectToSessionOverview();
      }
    }
  }, [currentRunId, runs, redirectToSessionOverview]);

  const handleEndSessionRun = () => {
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure you want to end session?')) {
      onDone();
      onEndSessionRun(
        Number(params.sessionId),
        sessionOverview(parseInt(params.sessionId!, 10)),
      );
    }
  };

  const currentRun = runs.find((run: SessionRunType) => run.id === currentRunId);

  const pilotFlying = currentRun?.pilotFlying?.name;
  const pilotMonitoring = currentRun?.pilotMonitoring?.name;

  const pageTitle = 'name' in currentSession
    ? `${currentSession.name} | ${config.appName}`
    : config.appName;

  const courseName = 'courseName' in currentSession
    ? currentSession.courseName.toUpperCase()
    : '';

  const session = currentSession as unknown as FullSessionType;

  const breadcrumbs:BreadcrumbListType = [
    {
      link: home(),
      title: 'Home',
    },
    {
      link: scheduleRoute(),
      title: 'Schedule',
    },
    {
      title: currentSession?.name,
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          {pageTitle}
        </title>
      </Helmet>
      <Header
        simulatorStatus={simulatorStatus}
      />

      {trainingCenter === ETrainingCenter.LEONARDO ? (
        <VideoDemoPage session={session} onEndSession={handleEndSessionRun} />
      ) : (
        <Body>
          <ContentContainer>
            <Breadcrumbs crumbs={breadcrumbs} />

            <SessionDetails>
              <Course>{courseName}</Course>
              <TraineeInfo>
                <Trainee>
                  <Name data-pilot-seat="flying">{pilotFlying}</Name>
                  <Description>PILOT FLYING</Description>
                </Trainee>
                {pilotMonitoring && (
                <Trainee>
                  <Name data-pilot-seat="monitoring">{pilotMonitoring}</Name>
                  <Description>PILOT MONITORING</Description>
                </Trainee>
                )}
              </TraineeInfo>
            </SessionDetails>

            {isLoading
              ? <Loading />
              : <LessonPlan onEndSessionRun={handleEndSessionRun} />}
          </ContentContainer>
        </Body>
      )}
    </>
  );
};

export default LiveTraining;

const SessionDetails = styled.div`
  background-color: ${props => props.theme.background.string()};
  width: 100%;
  z-index: 11;
  margin: auto;
  text-align: center;
  border-bottom: 3px solid ${mediumLightGrey.toString()};
  padding-bottom: 24px;
`;

const Course = styled.div`
  font-size: 24px;
  line-height: 28px;
  margin-top: 15px;
  margin-bottom: 16px;
`;

const Description = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: #9C9C9C;
`;

const TraineeInfo = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
`;

const Trainee = styled.div`
  font-size: 16px;
  line-height: 36px;
`;

const Name = styled.div`
  height: 36px;
`;
