import * as React from 'react';
import styled from 'styled-components';
import { GradeLevelType, GradeType } from '@/summary/constants';
import { competencyShortNameMapTable } from '@/competency/constants';
import { CompetencyCode, CompetencyScores } from '@/competency/types';
import GradeInput from '@/summary/components/GradeInput';
import { lightGrey, mediumGrey } from '@/theme/palette';
import * as actions from '@/competency/actions';
import { useAppDispatch } from '@/store';
import { NavLink } from 'react-router-dom';
import ArrowIcon from '@/assets/icons/ArrowIcon.svg';
import { sortBy } from 'lodash';
import { competencyRoute } from '@/utils/routes';
import { Accordion } from '@/components/Accordion';

type Props = {
  scores: CompetencyScores,
  instructorScores: GradeType,
  isLoading?: boolean
  runId: number
  sessionId: number
}

export const CompetencyScore = (props: Props) => {
  const {
    scores,
    instructorScores,
    isLoading,
    runId,
    sessionId,
  } = props;
  const dispatch = useAppDispatch();

  // Convert a competency object to legacy format.
  const competencyValuesTable: Array<{ grade: GradeLevelType | null, name: string, key: string }> = [];

  if (scores) {
    competencyShortNameMapTable.forEach((name: string, key: CompetencyCode) => {
      competencyValuesTable.push({
        name,
        grade: key in scores ? scores[key].score : null,
        key,
      });
    });
  }
  const onUpdateCompetencyScore = (competency: string, score: GradeType, level: GradeLevelType) => dispatch(
    actions.updateCompetencyScore(runId, competency, score, level),
  );

  return (
    <ScoresWrapper>
      <Accordion title="COMPETENCY SCORES">
        <ScoresBody>

          {!isLoading && sortBy(competencyValuesTable, value => (
            value.grade === null || value.grade <= 0 ? 1 : 0
          )).map(score => {
            // @ts-ignore
            const instructorScore = instructorScores[`${score.key}Grade`] || null;
            const handleUpdateCompetencyScore = (level: GradeLevelType) => onUpdateCompetencyScore(
              `${score.key}Grade`,
              instructorScores,
              level,
            );
            return (
              <Score key={score.key}>
                <ScoreLink to={competencyRoute(sessionId, runId.toString(), score.key)}>
                  {score.name}
                </ScoreLink>
                <Grades>
                  <GradeInput
                    key={`competency-${score.key}`}
                    gradeValue={instructorScore !== null ? instructorScore : score.grade}
                    type="competency"
                    onUpdateGradeLevel={handleUpdateCompetencyScore}
                    isPredicted={instructorScore === null}
                  />
                </Grades>
                <ArrowWrapperIcon to={competencyRoute(sessionId, runId.toString(), score.key)}>
                  <img src={ArrowIcon} alt="arrow icon" />
                </ArrowWrapperIcon>
              </Score>
            );
          })}
        </ScoresBody>

      </Accordion>
    </ScoresWrapper>
  );
};

const ScoresWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const ScoresBody = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 4px;
  box-sizing: border-box;
  padding: 16px;
`;

const Score = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.theme.mediumLightGrey.string()};
  border-radius: 4px;
  width: 100%;
  padding: 14px 16px 14px 22px;
  box-sizing: border-box;
  min-height: 64px;
`;

const Grades = styled.div`
  position: absolute;
  right: 36px;
  top: 50%;
  transform: translateY(-50%);
`;

const ScoreLink = styled(NavLink)`
  display: flex;
  color: ${({ theme }) => theme.white.string()};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  padding-right: 100px;
`;
const ArrowWrapperIcon = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 16px;
  min-height: 16px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transform: rotate(90deg);
  }
`;

export default CompetencyScore;
